import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { backBtnIcon, checkIcon } from "../../icon/icon";
import ProductDetailing from "./ProductDetailing";
import ProductSampling from "./ProductSampling";
import MarketResearch from "./MarketResearch";
import JointCall from "./JointCall";
import CallSummary from "./CallSummary";
import PostCall from "./PostCall";
import { DataNotFound, CallActivitiesMDDetails } from "../../components";
import {
  modifyText,
  mediaToBase64,
  nameFormatting,
  getLocationCoordinates,
  getTimeDifferenceInMin,
} from "../../helpers/commonFunction";
import { DoctorIcon } from "../../images";
import {
  getCallActivitieDataFromDB,
  getProductDetalingDataFromDB,
  getMarketResearchDataFromDB,
  reqToAddMDExcuse,
  resetActivitiyObject,
  getJointCallDataFromDB,
  getPromoTypeDataFromDB,
  getAllProductSamplesDataFromDB,
  reqToGetProductSampleData,
  productDObjHandler,
  marketRObjHandler,
  productSObjHandler,
  jointCallObjHandler,
  mdDetailsObjHandler,
} from "../../store/slice/callRecordingSlice";
import { Confirmation, MdExcuseModal } from "../../components/Modals";
import { useForm } from "../../hooks";
import { mdExcuseValidations } from "../../helpers/formValidations";
import { useLiveQuery } from "dexie-react-hooks";
import {
  db,
  insertEntryInDB,
  getAllEntriesFromDB,
  getEntryByFieldFromDB,
} from "../../helpers/db";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { routes } from "../../helpers/constants";
import { getMediaFileFromDB } from "../../helpers/offlineAPIs";

const CallActivities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedMD = location?.state?.selectedMD;

  const getProductsDataFromDB = useLiveQuery(() => db.products.toArray(), []);
  const getActivitiesDataFromDB = useLiveQuery(
    () => db.activities.toArray(),
    []
  );
  const {
    callActivitieData,
    activities,
    productDObj,
    uniqueProductDObj,
    marketRObj,
    productSObj,
    jointCObj,
    mdDetails,
  } = useSelector((state) => state.callRecordingReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState();
  const [callActivitieList, setCallActivitieList] = useState([]);
  const [showActivitySidebars, setShowActivitySidebars] = useState({
    productDetailing: false,
    marketResearch: false,
    productSampling: false,
    jointCall: false,
  });
  const [locationError, setLocationError] = useState("");
  const [locationModal, setLocationModal] = useState(false);
  const handleLocationModal = (item) => {
    if (item) {
      setLocationError(item);
    } else {
      setLocationError("");
    }
    setLocationModal(!locationModal);
  };
  const [showCallSummary, setShowCallSummary] = useState(false);
  const [showPostCall, setShowPostCall] = useState(false);
  const [showMDExcuse, setShowMDExcuse] = useState(false);
  const [previousPage, setPreviousPage] = useState("");
  const [showMatsModal, setShowMatsModal] = useState(false);

  useEffect(() => {
    if (getActivitiesDataFromDB && getActivitiesDataFromDB?.length > 0) {
      dispatch(getCallActivitieDataFromDB(getActivitiesDataFromDB));
    }
  }, [getActivitiesDataFromDB]);
  useEffect(() => {
    if (location?.state?.editableData) {
      const currEditableCall = location?.state?.editableData;
      const editableData = currEditableCall?.data;
      handleRestoreEditCall(editableData);
    } else {
      const handleGetCurrEditableCall = async () => {
        const [editableCalls] = await Promise.all([
          getAllEntriesFromDB("editableCalls"),
        ]);
        if (editableCalls?.length > 0) {
          const currEditableCall = editableCalls.filter(
            (item) =>
              item?.md_id === selectedMD?.md_id &&
              item?.target_date === location?.state?.selectedDate
          );
          if (currEditableCall?.length > 0) {
            const editableData = currEditableCall[0]?.data;
            handleRestoreEditCall(editableData);
          }
        }
        if (
          !location?.state?.is_incidental &&
          location?.state?.quickSignObj &&
          location?.state?.quickSignObj?.signature_url
        ) {
          handleSaveCallRecordingData("", "", "", "summary");
        }
      };
      handleGetCurrEditableCall();
    }
  }, [location?.state?.editableData]);
  const handleRestoreEditCall = async (editableData) => {
    dispatch(productDObjHandler(editableData?.products));
    dispatch(marketRObjHandler(editableData?.researches));
    dispatch(productSObjHandler(editableData?.sampling));
    if (editableData?.jointCall) {
      let dmObj = { ...editableData?.jointCall };
      if (editableData?.jointCall?.signature_url) {
        delete dmObj.signPadData;
        const base64 = await getMediaFileFromDB(
          dmObj,
          "signature_url",
          "signPadData"
        );
        dmObj = { ...dmObj, signPadData: base64 };
        dispatch(jointCallObjHandler(dmObj));
      } else if (editableData?.jointCall?.signPadData) {
        const bolbData = dataURLtoBlob(editableData?.jointCall?.signPadData);
        dmObj = { ...dmObj, signature_url: bolbData };
        dispatch(jointCallObjHandler(dmObj));
      } else {
        dispatch(jointCallObjHandler(dmObj));
      }
    }
    if (editableData?.mdDetails) {
      let mdObj = { ...editableData?.mdDetails };
      if (editableData?.mdDetails?.signature_url) {
        delete mdObj.signPadData;
        const base64 = await getMediaFileFromDB(
          mdObj,
          "signature_url",
          "signPadData"
        );
        mdObj = { ...mdObj, signPadData: base64 };
        dispatch(mdDetailsObjHandler(mdObj));
      } else if (editableData?.mdDetails?.signPadData) {
        const bolbData = dataURLtoBlob(editableData?.mdDetails?.signPadData);
        mdObj = { ...mdObj, signature_url: bolbData };
        dispatch(mdDetailsObjHandler(mdObj));
      } else {
        dispatch(mdDetailsObjHandler(mdObj));
      }
    }
  };

  const initialFValues = {
    date: moment().format("YYYY-MM-DD"),
    reason: "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return mdExcuseValidations(fieldValues, temp, values, setValues, setErrors);
  };
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (
      location &&
      location?.state?.prevPath !== location.pathname &&
      !location?.state?.editableData
    ) {
      dispatch(resetActivitiyObject());
    }
  }, []);
  useEffect(() => {
    if (
      callActivitieData &&
      callActivitieData?.length > 0 &&
      !location?.state?.editableData
    ) {
      updateCallActivitieData();
    }
  }, [callActivitieData, location?.state?.editableData]);
  useEffect(() => {
    if (location?.state?.editableData) {
      let activityData = updateCallActivitieData();
      reStoreSavedCallData(
        activityData,
        uniqueProductDObj,
        marketRObj,
        productSObj,
        jointCObj
      );
    }
  }, [
    callActivitieData,
    location?.state?.editableData,
    uniqueProductDObj,
    marketRObj,
    productSObj,
    jointCObj,
  ]);
  const reStoreSavedCallData = (
    activityData,
    productD,
    marketR,
    productS,
    jointC
  ) => {
    const newData =
      activityData &&
      activityData.map((item) => {
        if (modifyText(item.name) === modifyText(activities?.productD)) {
          return {
            ...item,
            isCheck: productD?.length > 0 ? true : false,
            selectedData: productD,
          };
        } else if (modifyText(item.name) === modifyText(activities?.marketR)) {
          return {
            ...item,
            isCheck: true,
            selectedData: marketR,
          };
        } else if (modifyText(item.name) === modifyText(activities?.productS)) {
          return {
            ...item,
            isCheck: productS?.length > 0 ? true : false,
            selectedData: productS,
          };
        } else if (
          modifyText(item.name) === modifyText(activities?.jointCall)
        ) {
          return {
            ...item,
            isCheck:
              jointC && (jointC?.notes || jointC?.signature_url) ? true : false,
            selectedData: [jointC],
          };
        } else {
          return item;
        }
      });
    setCallActivitieList(newData);
  };
  const updateCallActivitieData = (name) => {
    if (name) {
      const newActivityData =
        callActivitieList &&
        callActivitieList.map((item) => {
          if (item.name === name) {
            return {
              ...item,
              isCheck:
                name === activities.productD
                  ? uniqueProductDObj?.length > 0
                    ? true
                    : false
                  : true,
              selectedData:
                name === activities.productD
                  ? uniqueProductDObj
                  : name === activities.marketR
                  ? marketRObj
                  : name === activities.productS
                  ? productSObj
                  : [jointCObj],
            };
          } else {
            return item;
          }
        });
      setCallActivitieList(newActivityData);
    } else {
      const newActivityData = callActivitieData
        ?.filter(
          (item) =>
            !modifyText(item.name).includes(modifyText(activities?.marketR))
        )
        ?.map((item) => {
          return {
            ...item,
            isCheck: false,
            selectedData: [],
          };
        });
      setCallActivitieList(newActivityData);
      return newActivityData;
    }
  };

  const handleShowCallSummary = (type) => {
    setShowCallSummary(!showCallSummary);
    if (showCallSummary) {
      setPreviousPage("");
      setSelectedActivity("");
    }
    if (type) {
      setPreviousPage(type);
    }
  };
  const handleShowPostCall = () => {
    if (previousPage === "jointCall") {
      setShowCallSummary(!showCallSummary);
      setShowActivitySidebars({
        ...showActivitySidebars,
        productDetailing: false,
        marketResearch: false,
        productSampling: false,
        jointCall: true,
      });
      setPreviousPage("");
    } else if (previousPage === "save") {
      setShowCallSummary(!showCallSummary);
      setPreviousPage("");
    } else {
      setSelectedActivity("");
      setShowPostCall(!showPostCall);
    }
  };
  const handleShowMDExcuse = () => {
    if (showMDExcuse === true) {
      setErrors("");
      setValues({
        ...values,
        date: moment().format("YYYY-MM-DD"),
        reason: null,
      });
    }
    setShowMDExcuse(!showMDExcuse);
  };

  const handleCallActivitiesSidebar = (item, type) => {
    if (item && item) {
      setSelectedActivity(item);
    }
    if (item?.name === activities.productD) {
      handleProductDetailingData();
    } else if (item?.name === activities.marketR) {
      handleMarketResearchData();
    } else if (item?.name === activities.productS) {
      handleProductSamplesData();
    } else if (item?.name === activities.jointCall) {
      if (type === "next") {
        handleShowPostCall();
        setShowActivitySidebars({
          ...showActivitySidebars,
          jointCall: false,
        });
      } else {
        handleJointCallData();
      }
    } else {
      setShowActivitySidebars({
        ...showActivitySidebars,
        productDetailing: false,
        marketResearch: false,
        productSampling: false,
        jointCall: false,
      });
      setShowMatsModal(false);
      setSelectedActivity("");
    }
  };
  const handleProductDetailingData = () => {
    dispatch(
      getProductDetalingDataFromDB(
        getActivitiesDataFromDB[0]?.product_detailing
      )
    );
    setShowActivitySidebars({
      ...showActivitySidebars,
      productDetailing: true,
      marketResearch: false,
      productSampling: false,
      jointCall: false,
    });
  };
  const handleMarketResearchData = () => {
    dispatch(
      getMarketResearchDataFromDB(getActivitiesDataFromDB[1]?.market_research)
    );
    setShowActivitySidebars({
      ...showActivitySidebars,
      productDetailing: false,
      marketResearch: true,
      productSampling: false,
      jointCall: false,
    });
  };
  const handleProductSamplesData = () => {
    dispatch(getAllProductSamplesDataFromDB(getProductsDataFromDB));
    dispatch(getPromoTypeDataFromDB(getActivitiesDataFromDB[2]?.promomat_type));
    setShowActivitySidebars({
      ...showActivitySidebars,
      productDetailing: false,
      marketResearch: false,
      productSampling: true,
      jointCall: false,
    });
    setShowMatsModal(true);
    const productSamples = {
      physician: [],
      commercial: [],
      giveaways: [],
      literature: [],
    };
    if (getProductsDataFromDB && getProductsDataFromDB?.length > 0) {
      const newData = getProductsDataFromDB || [];
      for (let i = 0; i < newData?.length; i++) {
        if (newData[i].promomat_type.toUpperCase().includes("PHYSICIAN")) {
          productSamples.physician.push(newData[i]);
        }
        if (newData[i].promomat_type.toUpperCase().includes("COMMERCIAL")) {
          productSamples.commercial.push(newData[i]);
        }
        if (newData[i].promomat_type.toUpperCase().includes("GIVEAWAYS")) {
          productSamples.giveaways.push(newData[i]);
        }
        if (newData[i].promomat_type.toUpperCase().includes("LITERATURE")) {
          productSamples.literature.push(newData[i]);
        }
      }
      dispatch(reqToGetProductSampleData(productSamples));
      setShowActivitySidebars({
        ...showActivitySidebars,
        productDetailing: false,
        marketResearch: false,
        productSampling: true,
        jointCall: false,
      });
    } else {
      dispatch(reqToGetProductSampleData(productSamples));
      setShowActivitySidebars({
        ...showActivitySidebars,
        productDetailing: false,
        marketResearch: false,
        productSampling: true,
        jointCall: false,
      });
    }
  };
  const handleJointCallData = () => {
    dispatch(getJointCallDataFromDB(getActivitiesDataFromDB[3]?.joint_call));
    handleShowCallSummary("jointCall");
  };
  const onAddMDExcuseHandler = () => {
    const tempObj = {
      md_id: location?.state?.reqObj?.md_id,
      date: values.date,
      note_id: values.reason,
    };
    if (validate()) {
      dispatch(
        reqToAddMDExcuse({
          data: tempObj,
          onSuccess: () => {
            handleShowMDExcuse();
          },
        })
      );
    }
  };

  // Transform Data
  const transformProductDetailing = (productDObj) => {
    return productDObj?.map(
      ({ product_id, rank, product_start_time, product_end_time }) => ({
        product_id,
        rank,
        product_start_time,
        product_end_time,
      })
    );
  };
  const transformMarketResearch = (marketRObj) => {
    return marketRObj?.map((item) =>
      item.answer_id
        ? { answer_id: item.answer_id, question_id: item.question_id }
        : { answer_text: item.answer_text, question_id: item.question_id }
    );
  };
  const transformSampling = (productSObj) => {
    return productSObj?.map((sobj) => ({
      promomat_id: sobj.product_id,
      quantity: sobj.quantity,
      lot_number: sobj.lot_number || null,
      promomat_type:
        sobj.promomat_type === "Physician Samples"
          ? "P"
          : sobj.promomat_type === "Giveaways"
          ? "G"
          : sobj.promomat_type === "Literature"
          ? "L"
          : "C",
    }));
  };
  const convertSignatureToBase64 = async (signatureUrl) => {
    return signatureUrl?.size
      ? await mediaToBase64(signatureUrl)
      : signatureUrl;
  };
  const handleFindExistDataInDB = async (savedCall) => {
    const [
      callRecording,
      editableCalls,
      cumulativeCalls,
      // specialCallData,
      incidentalCalls,
      addedCumulativeCalls,
      getCurrentMDCalls,
    ] = await Promise.all([
      getAllEntriesFromDB("callRecording"),
      getAllEntriesFromDB("editableCalls"),
      getAllEntriesFromDB("cumulativeCalls"),
      // getAllEntriesFromDB("specialCallData"),
      getAllEntriesFromDB("incidentalCalls"),
      getAllEntriesFromDB("addedCumulativeCalls"),
      getEntryByFieldFromDB("mdList", "md_id", selectedMD.md_id),
    ]);
    const callMDId = location?.state?.reqObj?.md_id;
    const target_date = savedCall?.s_target_date
      ? savedCall?.s_target_date
      : location?.state?.selectedDate
      ? location?.state?.selectedDate
      : moment().format("YYYY-MM-DD");

    const getEData = editableCalls.filter(
      (item) => item.md_id === callMDId && item.target_date === target_date
    );

    const getCData = callRecording.filter(
      (item) =>
        item?.callActivity?.md_id === callMDId &&
        item?.callActivity?.target_date === target_date
    );

    const getCRData = cumulativeCalls.filter(
      (item) => item.md_id === callMDId && item.target_date === target_date
    );

    const getLocalCRData = addedCumulativeCalls.filter(
      (item) => item.md_id === callMDId && item.target_date === target_date
    );

    // const getSCData =
    //   specialCallData &&
    //   specialCallData.filter(
    //     (item) => item.md_id === callMDId && item.target_date === target_date
    //   );

    const newIncidentalCall =
      incidentalCalls?.length > 0 &&
      incidentalCalls[incidentalCalls?.length - 1];
    return {
      getEData,
      getCData,
      getCRData,
      // getSCData,
      getLocalCRData,
      newIncidentalCall,
      getCurrentMDCalls,
    };
  };
  const handleAddIncidentalCall = async (address, callSummaryObj) => {
    const addedIncidentalCalls1 = await getAllEntriesFromDB("incidentalCalls");
    const exitInciCall = addedIncidentalCalls1.filter((item) => {
      const { seconds } = getTimeDifferenceInMin(new Date(), item.record_time);
      if (
        seconds < 20 &&
        item?.firstname === selectedMD?.firstname &&
        item?.lastname === selectedMD?.lastname
      ) {
        return item;
      }
    });
    if (exitInciCall && exitInciCall?.length > 0) {
      exitInciCall.forEach(async (item) => {
        await db.incidentalCalls.delete(item.id);
      });
    }
    await db.incidentalCalls.add({
      address: null,
      latitude: address && address?.latitude ? address?.latitude : null,
      longitude: address && address?.longitude ? address?.longitude : null,
      class_name: selectedMD?.class_name,
      firstname: selectedMD?.firstname,
      lastname: selectedMD?.lastname,
      data: callSummaryObj,
      record_time: new Date(),
      specialty_name: selectedMD?.specialty_name,
      actual_firstname: selectedMD?.firstname,
      actual_lastname: selectedMD?.lastname,
    });
  };

  const handleSubmitCallRecordingData = async (
    postData,
    callType,
    mdDetail,
    jointDetail,
    prevPage
  ) => {
    const address = await getLocationCoordinates().catch(() => "");
    await handleCallRecordingOBJ(
      address,
      postData,
      callType,
      mdDetail,
      jointDetail,
      prevPage
    );
  };

  const handleCallRecordingOBJ = async (
    address,
    postData,
    callType,
    mdDetail,
    jointDetail,
    prevPage
  ) => {
    try {
      if (!prevPage) setIsLoading(true);
      const savedCall = location?.state?.editableData;
      const newMDDetails = mdDetail || mdDetails;
      const newJointCObj = jointDetail || jointCObj;
      const newProductDObj = transformProductDetailing(productDObj);
      const newMarketRObj = transformMarketResearch(marketRObj);
      const newProductSObj = transformSampling(productSObj);

      let quickSignObj =
        location?.state?.quickSignObj &&
        typeof location?.state?.quickSignObj === "object" &&
        Object.keys(location?.state?.quickSignObj)?.length > 0
          ? { call_id: "", ...location?.state?.quickSignObj }
          : null;
      if (quickSignObj && location?.state?.quickSignObj?.id) {
        const quickSID = location?.state?.quickSignObj?.id;
        const [getQSign1, getQSign2] = await Promise.all([
          db.quickSign.get(quickSID),
          db.addedQuickSign.get(quickSID),
        ]);
        if (getQSign1 && !getQSign2) {
          Object.assign(quickSignObj, {
            quick_sign_id: getQSign1.signature_id,
            signature_id: getQSign1.signature_id,
            signature_url: getQSign1.signature_url,
            signature_time: getQSign1.signature_time,
            signature_erase: getQSign1.signature_erase,
            signature_type: getQSign1.signature_type,
            signPadData: "",
          });
        }
      }

      const mdSign = await convertSignatureToBase64(
        newMDDetails?.signature_url
      );
      const dmSign = await convertSignatureToBase64(
        newJointCObj?.signature_url
      );
      const quickSign = await convertSignatureToBase64(
        quickSignObj?.signature_url
      );

      const mdFinalObj = newMDDetails?.signature_url
        ? {
            ...newMDDetails,
            signature_url: !mdSign?.includes("base64") ? mdSign : "",
          }
        : newMDDetails;
      const dmFinalObj = newJointCObj?.signature_url
        ? {
            ...newJointCObj,
            signature_url: !dmSign?.includes("base64") ? dmSign : "",
          }
        : newJointCObj;
      const quickFinalObj = quickSignObj
        ? {
            ...quickSignObj,
            signature_url: !quickSign?.includes("base64") ? quickSign : "",
          }
        : null;

      const callObj = {
        products: productDObj,
        researches: marketRObj,
        sampling: productSObj,
        mdDetails: mdFinalObj,
        jointCall: dmFinalObj,
        quickSignObj: quickFinalObj,
        postCall: postData,
        start_time: location?.state?.start_time,
      };
      const callSummaryObj = {
        productDetailing: uniqueProductDObj,
        marketResearch: marketRObj,
        ProductSampling: newProductSObj,
        mdDetails: mdFinalObj,
        jointCall: dmFinalObj,
        quickSignObj: quickFinalObj,
        signature_type:
          quickSignObj?.signature_type || newMDDetails?.signature_type,
        signature_url: quickSign || mdSign,
        signature_time: quickSignObj?.date || newMDDetails?.signature_md_time,
        reliever_md: newMDDetails?.reliever,
        postCall: postData,
      };

      if (location?.state?.is_incidental) {
        await handleAddIncidentalCall(address, callSummaryObj);
      }

      const {
        getEData,
        getCData,
        getCRData,
        getLocalCRData,
        newIncidentalCall,
        getCurrentMDCalls,
      } = await handleFindExistDataInDB(savedCall);

      const callReqObj = {
        callActivity: {
          md_table_id: selectedMD?.id,
          md_id: location?.state?.reqObj?.md_id,
          is_virtual: false,
          start_time: location?.state?.start_time,
          record_time: savedCall?.s_record_time || new Date(),
          target_date:
            savedCall?.s_target_date ||
            location?.state?.selectedDate ||
            moment().format("YYYY-MM-DD"),
          isQuicksign: quickSignObj && quickSignObj?.isQuick ? 1 : 0,
          is_incidental: location?.state?.is_incidental ? true : false,
          incidental_table_id: location?.state?.is_incidental
            ? newIncidentalCall?.id
            : null,
          save_id: savedCall?.save_id || "",
        },
        products: {
          call_id: "",
          product: newProductDObj,
          activity_id: callActivitieData && callActivitieData[0]?.id,
        },
        researches: {
          call_id: "",
          research: newMarketRObj,
          activity_id: callActivitieData && callActivitieData[1]?.id,
        },
        sampling: {
          call_id: "",
          product: newProductSObj,
          activity_id: callActivitieData && callActivitieData[2]?.id,
        },
        jointCall: {
          call_id: "",
          notes_id: newJointCObj.notes_id,
          notes: newJointCObj.notes,
          signature_url: dmSign,
          signature_type: newJointCObj?.signature_type,
          activity_id: callActivitieData && callActivitieData[3]?.id,
          dm_name: newJointCObj?.dm_name,
          signature_time: newJointCObj?.speed,
          signature_erase: newJointCObj?.eraseCount,
        },
        quickSignObj: { ...quickSignObj, signature_url: quickSign },
        mdDetails: {
          call_id: "",
          signature_url: mdSign,
          signature_type: newMDDetails?.signature_type,
          post_call: postData?.post_call,
          address: null,
          latitude: address?.latitude || null,
          longitude: address?.longitude || null,
          signature_md_time: newMDDetails?.signature_md_time,
          signature_time: newMDDetails?.speed,
          signature_erase: newMDDetails?.eraseCount,
          reliever_md: newMDDetails?.reliever,
        },
      };
      const saveCallObj = {
        md_table_id: selectedMD?.id,
        start_time: location?.state?.start_time,
        record_time: savedCall?.s_record_time || new Date(),
        md_id: location?.state?.reqObj?.md_id,
        target_date:
          savedCall?.s_target_date ||
          location?.state?.selectedDate ||
          moment().format("YYYY-MM-DD"),
        save_id: savedCall?.save_id || "",
        data: callObj,
        signature_id: quickSignObj?.signature_id || "",
      };
      const cumulativeObj = {
        md_id: selectedMD?.md_id,
        cycle_id: selectedMD?.cycle_id ? selectedMD?.cycle_id : "",
        is_save: callType === "save" ? 1 : 0,
        is_joint: newJointCObj?.notes || dmSign ? 1 : 0,
        isQuicksign: quickSignObj && quickSignObj?.isQuick ? 1 : 0,
        is_incidental: location?.state?.is_incidental ? 1 : 0,
        start_time: location?.state?.start_time,
        record_time: savedCall?.s_record_time || new Date(),
        target_date:
          savedCall?.s_target_date ||
          location?.state?.selectedDate ||
          moment().format("YYYY-MM-DD"),
        data: {
          selectedMD: selectedMD,
          summary_data: callSummaryObj,
        },
      };

      if (callType === "save") {
        const callID = await insertSavedCallDataInDB(getEData, saveCallObj);
        await insertCumulativeDataInDB(
          getCRData,
          getLocalCRData,
          callID,
          cumulativeObj
        );
      } else {
        const callID = await insertCallDataInDB(
          getCData,
          callReqObj,
          getEData,
          getCRData
        );
        await insertCumulativeDataInDB([], [], callID, cumulativeObj);
      }

      let currMDCalls = 0;
      const newGetMDListDataFromDB =
        getCurrentMDCalls &&
        getCurrentMDCalls?.length > 0 &&
        getCurrentMDCalls.map((item) => {
          let data =
            item.is_visited === 1 &&
            item.is_summary === 0 &&
            item.today !== moment().format("YYYY-MM-DD")
              ? { ...item, is_visited: 0 }
              : item;
          if (item.md_id === selectedMD.md_id && item.is_summary === 1) {
            currMDCalls = currMDCalls + 1;
          }
          return data;
        });
      newGetMDListDataFromDB &&
        newGetMDListDataFromDB.map(async (item) => {
          let data;
          if (
            item.md_id === selectedMD.md_id &&
            item.is_visited === 0 &&
            item.is_summary === 0 &&
            item.date === location?.state?.selectedDate
          ) {
            data = {
              ...item,
              save_data: JSON.stringify(callObj),
              save_record_date: saveCallObj.record_time,
              save_target_date: saveCallObj.target_date,
              save_id: saveCallObj?.save_id,
              is_visited: callType === "save" ? 0 : 1,
              actual_visits:
                callType === "save" ? currMDCalls : currMDCalls + 1,
              is_consecutive: callType === "save" ? 0 : 1,
              is_summary: callType === "save" ? 0 : 1,
              visited_date: moment(new Date()).format("YYYY-MM-DD"),
              save_visited_date: moment(new Date()).format("YYYY-MM-DD"),
              is_save: callType === "save" ? 1 : 0,
              data: callSummaryObj,
              record_date: new Date(),
              today: moment().format("YYYY-MM-DD"),
              target_date: location?.state?.selectedDate,
            };
            await db.mdList.update(item.id, data);
          } else if (
            item.md_id === selectedMD.md_id &&
            item.date !== location?.state?.selectedDate &&
            item.is_summary !== 1
          ) {
            data = {
              ...item,
              is_visited: 1,
              is_consecutive: 1,
              actual_visits: currMDCalls + 1,
            };
            await db.mdList.update(item.id, data);
          } else {
            data = {
              ...item,
              is_consecutive: 0,
            };
            await db.mdList.update(item.id, data);
          }
        });

      if (
        location?.state?.quickSignObj?.id &&
        (location?.state?.quickSignObj?.isQuick ||
          location?.state?.is_incidental)
      ) {
        await db.quickSign.delete(location?.state?.quickSignObj?.id);
        await db.addedQuickSign.delete(location?.state?.quickSignObj?.id);
      }

      if (!prevPage) {
        handleShowCallSummary();
        handleShowPostCall();
        navigate(
          location?.state?.is_incidental
            ? location?.state?.prevPath
            : routes.mdList,
          {
            state: {
              prevPath: location.pathname,
              selectedDate: location?.state?.selectedDate,
            },
          }
        );
        dispatch(productDObjHandler([]));
        dispatch(marketRObjHandler([]));
        dispatch(productSObjHandler([]));
        dispatch(jointCallObjHandler([]));
        dispatch(mdDetailsObjHandler([]));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const insertCallDataInDB = async (
    getCData,
    callReqObj,
    getEData,
    getCRData
  ) => {
    if (getEData?.length > 0) {
      await db.editableCalls.delete(getEData[0]?.id);
    }
    if (getCRData?.length > 0) {
      getCRData.forEach(async (item) => {
        await db.cumulativeCalls.delete(item.id);
        await db.addedCumulativeCalls.delete(item.id);
      });
    }
    if (getCData?.length > 0) {
      getCData.forEach(async (item) => {
        await db.callRecording.delete(item.id);
      });
    }
    const callID = await insertEntryInDB("callRecording", callReqObj);
    return callID;
  };

  const insertSavedCallDataInDB = async (getEData, saveCallObj) => {
    let callID = "";
    if (getEData?.length > 0) {
      callID = getEData[0]?.id;
      await db.editableCalls.update(getEData[0]?.id, saveCallObj);
    } else {
      callID = await insertEntryInDB("editableCalls", saveCallObj);
    }
    return callID;
  };

  const insertCumulativeDataInDB = async (
    getCRData,
    getLocalCRData,
    callID,
    cumulativeObj
  ) => {
    if (getCRData?.length > 0) {
      await db.cumulativeCalls.update(getCRData[0]?.id, cumulativeObj);
      if (getLocalCRData?.length > 0) {
        await db.addedCumulativeCalls.update(getCRData[0]?.id, {
          ...cumulativeObj,
          edit_call_id: callID,
        });
      } else {
        await insertEntryInDB("addedCumulativeCalls", {
          ...cumulativeObj,
          id: getCRData[0]?.id,
          edit_call_id: callID,
        });
      }
    } else {
      const insertID = await insertEntryInDB("cumulativeCalls", cumulativeObj);
      await db.addedCumulativeCalls.add({
        ...cumulativeObj,
        id: insertID,
        edit_call_id: callID,
      });
    }
  };

  const handleCheckSignature = (type) => {
    if (mdDetails?.signature_url || location?.state?.quickSignObj) {
      handleSaveCallRecordingData(mdDetails);
    } else {
      handleShowCallSummary(type);
    }
  };

  const handleSaveCallRecordingData = (
    mdDetail,
    joinCallDetail,
    postCall,
    prevPage
  ) => {
    const reqObj = {
      call_id: "",
      signature_url: "",
      post_call: "",
      next_obj: "",
    };
    if (location?.state?.editableData) {
      const savedCall = location?.state?.editableData?.data?.postCall;
      reqObj.post_call = savedCall ? savedCall?.post_call : "";
    }
    const newReqObj = postCall ? postCall : reqObj;
    handleSubmitCallRecordingData(
      newReqObj,
      "save",
      mdDetail,
      joinCallDetail,
      prevPage
    );
  };

  const handleBack = () => {
    dispatch(productDObjHandler([]));
    dispatch(marketRObjHandler([]));
    dispatch(productSObjHandler([]));
    dispatch(jointCallObjHandler([]));
    dispatch(mdDetailsObjHandler([]));
    setIsLoading(false);
    if (location?.state?.prevPath === routes.missedCallMdList) {
      navigate(location?.state?.prevPath, {
        state: { selectedDate: location?.state?.missedCallDate },
      });
    } else {
      navigate(
        location?.state?.is_incidental
          ? location?.state?.prevPath
          : routes.mdList,
        {
          state: {
            prevPath: location.pathname,
            selectedDate: location?.state?.selectedDate,
          },
        }
      );
    }
  };

  return (
    <div className="tc-body tc-call-activities">
      <div className="tc-navbar-back-text">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => handleBack()}
        >
          {backBtnIcon}
        </button>
      </div>
      <div className="tc-profile-wrapper">
        <img src={DoctorIcon} alt="doctor" />
        <span className="tc-profile-name">
          {selectedMD && nameFormatting(selectedMD)}
        </span>
      </div>
      <div className="add-scroll">
        <div className="tc-content-wrapper">
          <CallActivitiesMDDetails selectedMD={selectedMD} />
          <div className="tc-content-wrapper-title d-flex justify-content-between extra-padding">
            <h2 className="m-0">CALL ACTIVITIES</h2>
            {callActivitieList && callActivitieList?.length > 0 && (
              <div className="tc-md-list-detail-bottom-btn-content text-center d-flex  gap-3">
                {!location?.state?.is_incidental && (
                  <Button
                    variant="primary"
                    type="button"
                    className="btn-min"
                    onClick={() => handleCheckSignature("save")}
                    disabled={
                      isLoading ||
                      (!location?.state?.quickSignObj &&
                        productDObj?.length === 0 &&
                        productSObj?.length === 0 &&
                        marketRObj?.length === 0 &&
                        jointCObj?.length === 0)
                    }
                  >
                    Save
                  </Button>
                )}
                <Button
                  variant="primary"
                  type="button"
                  className="btn-min"
                  onClick={() => handleShowCallSummary()}
                >
                  Sign
                </Button>
              </div>
            )}
          </div>
          <div className="tc-call-activity-list">
            {(callActivitieList && callActivitieList?.length === 0) ||
            callActivitieList === undefined ? (
              <DataNotFound />
            ) : (
              <>
                {callActivitieList &&
                  callActivitieList?.length > 0 &&
                  callActivitieList?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`tc-call-activity-list-row ${
                          item?.isCheck && "active"
                        }`}
                        onClick={() => {
                          handleCallActivitiesSidebar(item);
                        }}
                      >
                        <h5 className="mb-0">{item?.name}</h5>
                        <div className="pe-3 inner-scroll">
                          {item?.selectedData &&
                            item?.selectedData?.length > 0 &&
                            item?.selectedData?.map((list, key) => {
                              return (
                                <React.Fragment key={key}>
                                  {item.name === activities.productD && (
                                    <p className="mb-0 pe-2">
                                      {list?.name}{" "}
                                      <span>{list?.productValue} </span>
                                    </p>
                                  )}
                                  {item.name === activities.marketR && (
                                    <p className="mb-0 pe-2">
                                      {list?.question}{" "}
                                      <span>
                                        {list?.name
                                          ? list?.name
                                          : list?.answer_text}
                                      </span>
                                    </p>
                                  )}
                                  {item.name === activities.productS && (
                                    <p className="mb-0 pe-2">
                                      {list?.name} <span>{list?.quantity}</span>
                                    </p>
                                  )}
                                  {item.name === activities.jointCall && (
                                    <p className="mb-0 ">
                                      {list?.dm_name}
                                      <span>{list?.quantity}</span>
                                    </p>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </div>
                        <span className="ms-auto">
                          <button
                            className={`tc-activity-check-btn ${
                              item?.is_done
                                ? "active pointer-none"
                                : item.isCheck && "active"
                            }`}
                          >
                            {checkIcon}
                          </button>
                        </span>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </div>

      <ProductDetailing
        selectedMD={selectedMD}
        showSidebar={showActivitySidebars.productDetailing}
        selectedActivity={selectedActivity}
        handleCallActivitiesSidebar={handleCallActivitiesSidebar}
        updateCallActivitieData={updateCallActivitieData}
        savedCallData={location?.state?.editableData}
      />

      <MarketResearch
        selectedMD={selectedMD}
        selectedActivity={selectedActivity}
        showSidebar={showActivitySidebars.marketResearch}
        handleCallActivitiesSidebar={handleCallActivitiesSidebar}
        updateCallActivitieData={updateCallActivitieData}
      />

      <ProductSampling
        selectedMD={selectedMD}
        selectedActivity={selectedActivity}
        showSidebar={showActivitySidebars.productSampling}
        handleCallActivitiesSidebar={handleCallActivitiesSidebar}
        updateCallActivitieData={updateCallActivitieData}
        showMatsModal={showMatsModal}
        mdId={location?.state?.reqObj}
        setShowMatsModal={setShowMatsModal}
      />

      <JointCall
        location={location}
        isLoading={isLoading}
        selectedMD={selectedMD}
        selectedActivity={selectedActivity}
        showSidebar={showActivitySidebars.jointCall}
        handleCallActivitiesSidebar={handleCallActivitiesSidebar}
        updateCallActivitieData={updateCallActivitieData}
        savedCallData={location?.state?.editableData}
        handleSaveCallRecordingData={handleSaveCallRecordingData}
      />

      <CallSummary
        location={location}
        isLoading={isLoading}
        selectedMD={selectedMD}
        previousPage={previousPage}
        showCallSummary={showCallSummary}
        selectedActivity={selectedActivity}
        handleShowPostCall={handleShowPostCall}
        quickSignData={location?.state?.quickSignObj}
        handleShowCallSummary={handleShowCallSummary}
        savedCallData={location?.state?.editableData}
        handleSaveCallRecordingData={handleSaveCallRecordingData}
      />

      <PostCall
        location={location}
        isLoading={isLoading}
        selectedMD={selectedMD}
        showPostCall={showPostCall}
        handleShowPostCall={handleShowPostCall}
        savedCallData={location?.state?.editableData}
        handleSaveCallRecordingData={handleSaveCallRecordingData}
        handleSubmitCallRecordingData={handleSubmitCallRecordingData}
      />

      {showMDExcuse && (
        <MdExcuseModal
          show={showMDExcuse}
          onHide={handleShowMDExcuse}
          errors={errors}
          values={values}
          setValues={setValues}
          handleInputChange={handleInputChange}
          onAddMDExcuseHandler={onAddMDExcuseHandler}
        />
      )}

      {locationModal && (
        <Confirmation
          show={locationModal}
          onHide={handleLocationModal}
          type="location"
          save="Close"
          errorMessage={locationError}
          onSubmit={handleLocationModal}
        />
      )}
    </div>
  );
};

export default CallActivities;
