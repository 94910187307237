import React, { useEffect, useRef, useState } from "react";
import { backBtnIcon, deleteIcon, zoomIn } from "../../icon/icon";
import { Button, Form, Table } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import {
  mediaToBase64,
  nameFormatting,
  dateWithTimeFormat,
  getTimeDifference,
} from "../../helpers/commonFunction";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { mdDetailsObjHandler } from "../../store/slice/callRecordingSlice";
import { messages } from "../../helpers/messages";
import { SignZoomModal, RelieverModal } from "../../components/Modals";
import { DoctorIcon } from "../../images";
import { CallActivitiesMDDetails } from "../../components";
import { isDesktop } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

const CallSummary = (props) => {
  const {
    location,
    isLoading,
    selectedMD,
    previousPage,
    savedCallData,
    quickSignData,
    showCallSummary,
    handleShowPostCall,
    handleShowCallSummary,
    handleSaveCallRecordingData,
  } = props;
  const dispatch = useDispatch();
  const savedCall = savedCallData && savedCallData?.data?.mdDetails;
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  const {
    uniqueProductDObj: productDObj,
    jointCObj,
    mdDetails,
  } = useSelector((state) => state.callRecordingReducer);

  const smallPadRef = useRef(null);
  const bigPadRef = useRef(null);
  const [errors, setErrors] = useState("");
  const [screenShort, setScreenShort] = useState("");
  const [signatureData, setSignatureData] = useState("");
  const [bigPadSigned, setBigPadSigned] = useState(false);
  const [viewSignModal, setViewSignModal] = useState(false);
  const [isReliever, setIsReliever] = useState(false);
  const [match, setMatch] = useState("oldMD");
  const [newPPage, setNewPPage] = useState("");
  const [selectedReliever, setSelectedReliever] = useState("");
  const [showRelieverModal, setShowRelieverModal] = useState(false);
  const handleShowRelieverModal = () => {
    setShowRelieverModal(!showRelieverModal);
  };
  const [signTimeCount, setSignTimeCount] = useState({
    eraseCount: 0,
    signStart: "",
    signEnd: "",
    signDrawTime: 0,
  });
  const clearSingAndFileData = () => {
    if (screenShort) {
      setScreenShort("");
    } else {
      if (!smallPadRef?.current?.isEmpty()) {
        setSignTimeCount({
          ...signTimeCount,
          eraseCount: signTimeCount.eraseCount + 1,
          signDrawTime: 0,
          signStart: "",
          signEnd: "",
        });
        smallPadRef?.current?.clear();
        if (!bigPadRef?.current?.isEmpty()) {
          bigPadRef?.current?.clear();
        }
      } else if (!bigPadRef?.current?.isEmpty()) {
        setSignTimeCount({
          ...signTimeCount,
          eraseCount: signTimeCount.eraseCount + 1,
          signDrawTime: 0,
          signStart: "",
          signEnd: "",
        });
        bigPadRef?.current?.clear();
        if (!smallPadRef?.current?.isEmpty()) {
          smallPadRef?.current?.clear();
        }
      }
      setSignatureData("");
      setBigPadSigned(false);
    }
  };

  useEffect(() => {
    if (showCallSummary === true) {
      setErrors("");
      setNewPPage(previousPage);
      if (Object.keys(mdDetails && mdDetails)?.length === 0) {
        clearSingAndFileData();
        setSignTimeCount({
          eraseCount: 0,
          signStart: "",
          signEnd: "",
          signDrawTime: 0,
        });
        if (!quickSignData?.signature_url) {
          openBigSignModal();
        }
      } else {
        reStoreMDDetails();
      }
    }
  }, [showCallSummary, mdDetails]);

  useEffect(() => {
    if (savedCall) {
      reStoreMDDetails();
    }
  }, [savedCall, mdDetails]);

  const reStoreMDDetails = () => {
    if (!smallPadRef?.current?.isEmpty() || !bigPadRef?.current?.isEmpty()) {
      if (mdDetails?.reliever) {
        setIsReliever(true);
        setSelectedReliever(mdDetails?.reliever);
      }
      if (
        mdDetails?.sign_type === "sign" ||
        mdDetails?.sign_type === "SIGNATURE" ||
        mdDetails?.signature_type === "SIGNATURE"
      ) {
        smallPadRef?.current?.clear();
        setSignatureData(mdDetails?.signPadData);
        smallPadRef?.current?.fromDataURL(mdDetails?.signPadData);
      } else {
        smallPadRef?.current?.clear();
        setScreenShort(mdDetails?.signPadData);
      }
      setSignTimeCount({
        ...signTimeCount,
        eraseCount: mdDetails.eraseCount,
        signDrawTime: mdDetails.speed,
      });
    }
  };

  const handleSignBegin = () => {
    setSignTimeCount({
      ...signTimeCount,
      signStart: new Date(),
    });
  };
  const handleSmallPadEnd = () => {
    if (!smallPadRef?.current?.isEmpty()) {
      setErrors("");
    }
    setSignatureData(smallPadRef?.current?.toDataURL());
    const timeDiff = getTimeDifference(signTimeCount.signStart, new Date());
    setSignTimeCount({
      ...signTimeCount,
      signEnd: new Date(),
      signDrawTime: signTimeCount.signDrawTime + timeDiff,
    });
  };
  const handleBigPadEnd = () => {
    if (!bigPadRef?.current?.isEmpty()) {
      setErrors("");
    }
    setBigPadSigned(true);
    setSignatureData(bigPadRef?.current?.toDataURL());
    const timeDiff = getTimeDifference(signTimeCount.signStart, new Date());
    setSignTimeCount({
      ...signTimeCount,
      signEnd: new Date(),
      signDrawTime: signTimeCount.signDrawTime + timeDiff,
    });
  };
  const openBigSignModal = () => {
    setViewSignModal(true);
    if (smallPadRef.current && bigPadRef.current) {
      bigPadRef.current.clear();
      bigPadRef.current.fromDataURL(smallPadRef.current.toDataURL());
    }
  };
  const closeBigSignModal = () => {
    setViewSignModal(false);
    if (bigPadSigned) {
      setSignatureData(bigPadRef?.current?.toDataURL());
      if (smallPadRef.current) {
        smallPadRef.current.clear();
        smallPadRef.current.fromDataURL(bigPadRef.current.toDataURL());
      }
      setBigPadSigned(false);
    }
  };
  useEffect(() => {
    if (viewSignModal && smallPadRef.current && bigPadRef.current) {
      bigPadRef.current.clear();
      bigPadRef.current.fromDataURL(smallPadRef.current.toDataURL());
    }
  }, [viewSignModal]);
  useEffect(() => {
    if (bigPadRef.current && signatureData) {
      bigPadRef.current.fromDataURL(signatureData);
    }
  }, [signatureData]);
  useEffect(() => {
    if (smallPadRef.current && signatureData) {
      smallPadRef.current.clear();
      smallPadRef.current.fromDataURL(signatureData);
      setTimeout(() => {
        smallPadRef.current.clear();
        smallPadRef.current.fromDataURL(signatureData);
      }, 100);
    }
  }, [isLandscape, isPortrait, isReliever]);
  useEffect(() => {
    const handleResize = () => {
      if (smallPadRef.current && signatureData) {
        smallPadRef.current.clear();
        smallPadRef.current.fromDataURL(signatureData);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [signatureData, smallPadRef]);

  const handleCaptureImage = async (event) => {
    if (isDesktop) {
      const blobData = dataURLtoBlob(event);
      setScreenShort(blobData);
      setErrors("");
      setSignatureData("");
      if (newPPage === "save") {
        handleUploadMediaFiles(newPPage, blobData, event, "media");
      } else {
        if (!location?.state?.is_incidental) {
          handleUploadMediaFiles("preSave", blobData, event, "media");
        }
        setViewSignModal(false);
      }
    } else {
      const file =
        event?.target?.files &&
        event?.target?.files?.length > 0 &&
        event?.target?.files[0];
      if (file) {
        const base64 = await mediaToBase64(file);
        const blobData = dataURLtoBlob(base64);
        setScreenShort(blobData);
        setErrors("");
        setSignatureData("");
        if (newPPage === "save") {
          handleUploadMediaFiles(newPPage, blobData, base64, "media");
        } else {
          if (!location?.state?.is_incidental) {
            handleUploadMediaFiles("preSave", blobData, base64, "media");
          }
          setViewSignModal(false);
        }
      }
    }
  };
  const checkValidation = () => {
    if (screenShort === "" && !signatureData) {
      setErrors(messages.errors.sign_selfie_req);
      return false;
    } else {
      setErrors("");
      return true;
    }
  };
  const handleUploadMediaFiles = async (btnType, bigPad, base64, type) => {
    if (!quickSignData?.signature_url) {
      if ((btnType === "save" || btnType === "preSave") && type === "media") {
        handleFileUpload(bigPad, base64, "SELFIE", btnType);
      } else if (checkValidation()) {
        if (
          mdDetails?.signature_url &&
          !mdDetails?.signature_url?.size &&
          !mdDetails?.signature_url?.includes("base64")
        ) {
          handleFileUpload(
            mdDetails?.signature_url,
            mdDetails?.signPadData,
            mdDetails?.signature_type,
            btnType
          );
        } else if (screenShort && screenShort?.size) {
          const base64 = await mediaToBase64(screenShort);
          handleFileUpload(screenShort, base64, "SELFIE", btnType);
        } else if (screenShort && screenShort?.includes("base64")) {
          const blobData = dataURLtoBlob(screenShort);
          handleFileUpload(blobData, screenShort, "SELFIE", btnType);
        } else {
          const actualPad = bigPad ? bigPadRef : smallPadRef;
          const canvas = actualPad?.current?.toDataURL("image/png");
          const blobData = dataURLtoBlob(canvas);
          handleFileUpload(blobData, canvas, "SIGNATURE", btnType);
        }
      }
    } else if (newPPage === "save" || btnType === "save") {
      handleSaveCallRecordingData();
    } else {
      if (viewSignModal) {
        setViewSignModal(false);
      } else {
        if (!location?.state?.is_incidental) {
          handleSaveCallRecordingData("", "", "", "summary");
        }
        handleShowPostCall();
      }
    }
  };

  const handleFileUpload = (signData, signPadData, type, btnType) => {
    const tempObj = {
      id: "",
      name: "",
      signature_url: signData,
      signature_md_time: new Date(),
      speed: signTimeCount.signDrawTime,
      eraseCount: signTimeCount.eraseCount,
      signPadData: signPadData,
      signature_type: type,
      reliever: isReliever ? selectedReliever : "",
    };
    setTimeout(() => {
      dispatch(mdDetailsObjHandler(tempObj));
      setSignTimeCount({
        eraseCount: 0,
        signStart: "",
        signEnd: "",
        signDrawTime: 0,
      });
      if (newPPage === "save" || btnType === "save") {
        handleSaveCallRecordingData(tempObj);
        setTimeout(() => {
          setViewSignModal(false);
        }, 1000);
      } else {
        if (!location?.state?.is_incidental) {
          handleSaveCallRecordingData(tempObj, "", "", "summary");
        }
        if (viewSignModal) {
          setViewSignModal(false);
        } else {
          handleShowPostCall();
        }
      }
    }, 200);
  };
  const handleRelieverMD = (reliever) => {
    if (reliever) {
      setIsReliever(true);
      handleShowRelieverModal();
    } else {
      setIsReliever(false);
    }
  };

  return (
    <>
      <div
        className={`tc-sidebar-section ${showCallSummary && "zl-sidebar-open"}`}
      >
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => handleShowCallSummary()}
            disabled={isLoading}
          >
            {backBtnIcon}
          </button>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body-wrapper">
          <div className="tc-sidebar-body">
            <div className="tc-profile-wrapper">
              <img src={DoctorIcon} alt="doctor" />
              <span className="tc-profile-name">
                {selectedMD && nameFormatting(selectedMD)}
              </span>
            </div>
            <div className="h-100">
              <div className="tc-content-wrapper h-100">
                <CallActivitiesMDDetails selectedMD={selectedMD} />
                <div className="d-flex justify-content-between tc-content-wrapper-title align-items-center">
                  <h2 className="m-0">MD SIGNATURE</h2>
                  <div className="d-flex gap-3">
                    {!location?.state?.is_incidental && (
                      <Button
                        variant="primary"
                        type="button"
                        className="btn-min"
                        onClick={() => handleUploadMediaFiles("save")}
                        disabled={
                          isLoading ||
                          ((smallPadRef?.current?.isEmpty() === undefined ||
                            smallPadRef?.current?.isEmpty()) &&
                            !screenShort &&
                            !quickSignData?.signature_url)
                        }
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      type="button"
                      className="btn-min"
                      onClick={() => handleUploadMediaFiles()}
                      disabled={
                        isLoading ||
                        ((smallPadRef?.current?.isEmpty() === undefined ||
                          smallPadRef?.current?.isEmpty()) &&
                          !screenShort &&
                          !quickSignData?.signature_url)
                      }
                    >
                      Next
                    </Button>
                  </div>
                </div>
                {isReliever || quickSignData ? (
                  <div className="tc-table-wrapper min-height-370 m-0">
                    <Table
                      className="tc-table tc-border-bottom-table"
                      responsive
                    >
                      <thead>
                        <tr>
                          <th>Activity</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            className="text-start ps-0"
                            style={{ minWidth: 200 }}
                          >
                            Product Detailing
                          </td>
                          <td className="text-start">
                            {productDObj && productDObj?.length > 0
                              ? productDObj.map((item, i) => {
                                  return <p key={i}>{item.name}</p>;
                                })
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="text-start ps-0"
                            style={{ minWidth: 200 }}
                          >
                            Joint Call
                          </td>
                          <td className="text-start">
                            <p>
                              {`${
                                jointCObj && jointCObj?.dm_name
                                  ? "with " + jointCObj?.dm_name
                                  : jointCObj?.name
                                  ? "with " + jointCObj?.name
                                  : "-"
                              }`}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="text-start ps-0"
                            style={{ minWidth: 200 }}
                          >
                            Signature
                          </td>
                          <td>
                            <div
                              className={`tc-md-list-behavior-input-box ${
                                isLoading ||
                                savedCall?.signPadData ||
                                mdDetails?.signPadData ||
                                mdDetails?.signature_url
                                  ? "tc-call-edit-disabled"
                                  : ""
                              }`}
                            >
                              {quickSignData && quickSignData?.signature_url ? (
                                <div className="tc-quicklist-canvas">
                                  <img
                                    src={
                                      quickSignData?.signature_url?.size
                                        ? URL.createObjectURL(
                                            quickSignData.signature_url
                                          )
                                        : quickSignData?.signPadData?.includes(
                                            "base64"
                                          )
                                        ? quickSignData?.signPadData
                                        : process.env.REACT_APP_IMAGE_URL +
                                          quickSignData.signature_url
                                    }
                                    alt="Signature"
                                    draggable={false}
                                  />
                                </div>
                              ) : screenShort ? (
                                <div className="tc-call-summary-signature-box tc-call-summary-photo-upload-box tc-call-summary-photo-upload-img-box">
                                  {/* <button
                                    className="tc-call-summary-signature-erase-btn"
                                    onClick={clearSingAndFileData}
                                    disabled={isLoading}
                                  >
                                    Erase
                                  </button> */}
                                  <img
                                    className="tc-call-summary-photo-upload-img img-fluid"
                                    src={
                                      screenShort?.size
                                        ? URL.createObjectURL(screenShort)
                                        : screenShort?.includes("base64")
                                        ? screenShort
                                        : process.env.REACT_APP_IMAGE_URL +
                                          screenShort
                                    }
                                    alt="img"
                                    draggable={false}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="tc-quick-sign-signature-box"
                                  style={{ position: "relative" }}
                                >
                                  <div className="tc-signature-pad-icons">
                                    <button
                                      onClick={() => openBigSignModal()}
                                      disabled={isLoading}
                                    >
                                      {zoomIn}
                                    </button>
                                    {/* <button
                                      role="button"
                                      onClick={() => clearSingAndFileData()}
                                      disabled={isLoading}
                                    >
                                      {deleteIcon}
                                    </button> */}
                                  </div>
                                  <SignaturePad
                                    maxWidth={3}
                                    minWidth={3}
                                    canvasProps={{
                                      className:
                                        "tc-join-call-digi-signaturepad disabled",
                                      id: "sign-pad",
                                    }}
                                    ref={smallPadRef}
                                    onEnd={() => handleSmallPadEnd()}
                                    onBegin={() => handleSignBegin()}
                                  />
                                </div>
                              )}
                              {errors && (
                                <span className="tc-error">{errors}</span>
                              )}
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-1">
                              <p className="tc-signaturepad-time-text">
                                {dateWithTimeFormat(
                                  quickSignData?.date
                                    ? quickSignData?.date
                                    : new Date()
                                )}
                              </p>
                              {isReliever && (
                                <Form.Check
                                  className="tc-md-reliever-check ms-auto"
                                  type={"checkbox"}
                                  id="reliever"
                                  label="Reliever"
                                  checked={isReliever}
                                  onChange={() => handleRelieverMD()}
                                  disabled={isLoading ? true : false}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="tc-table-wrapper h-100">
                    <div className="d-flex justify-content-between align-items-center my-1 mb-2">
                      <Form.Check
                        className="tc-md-reliever-check"
                        type={"checkbox"}
                        id="reliever"
                        label="Reliever"
                        checked={isReliever}
                        onChange={() => handleShowRelieverModal()}
                        disabled={isLoading ? true : false}
                      />
                      <p className="tc-signaturepad-time-text tc-signpad-time mb-0">
                        {dateWithTimeFormat(
                          quickSignData?.date ? quickSignData?.date : new Date()
                        )}
                      </p>
                      <div></div>
                    </div>
                    <div
                      className={`pb-0 ${
                        isLoading ||
                        savedCall?.signPadData ||
                        mdDetails?.signPadData ||
                        mdDetails?.signature_url
                          ? "tc-call-edit-disabled"
                          : ""
                      }`}
                    >
                      <div className="tc-md-list-behavior-input-box">
                        {quickSignData && quickSignData?.signature_url ? (
                          <div className="tc-quicklist-canvas">
                            <img
                              src={
                                quickSignData?.signature_url?.size
                                  ? URL.createObjectURL(
                                      quickSignData.signature_url
                                    )
                                  : quickSignData?.signPadData?.includes(
                                      "base64"
                                    )
                                  ? quickSignData?.signPadData
                                  : process.env.REACT_APP_IMAGE_URL +
                                    quickSignData.signature_url
                              }
                              className="max-height-250"
                              alt="Signature"
                              draggable={false}
                            />
                          </div>
                        ) : screenShort ? (
                          <div className="tc-call-summary-signature-box tc-call-summary-photo-upload-box tc-call-summary-photo-upload-img-box">
                            {/* <button
                              className="tc-call-summary-signature-erase-btn"
                              onClick={() => clearSingAndFileData()}
                              disabled={isLoading}
                            >
                              Erase
                            </button> */}
                            <img
                              className="tc-call-summary-photo-upload-img img-fluid height-250"
                              src={
                                screenShort?.size
                                  ? URL.createObjectURL(screenShort)
                                  : screenShort?.includes("base64")
                                  ? screenShort
                                  : process.env.REACT_APP_IMAGE_URL +
                                    screenShort
                              }
                              alt="img"
                              draggable={false}
                            />
                          </div>
                        ) : (
                          <div
                            className="tc-quick-sign-signature-box"
                            style={{ position: "relative" }}
                          >
                            <div className="tc-signature-pad-icons">
                              <button
                                onClick={() => openBigSignModal()}
                                disabled={isLoading}
                              >
                                {zoomIn}
                              </button>
                              {/* <button
                                role="button"
                                onClick={() => clearSingAndFileData()}
                                disabled={isLoading}
                              >
                                {deleteIcon}
                              </button> */}
                            </div>
                            <SignaturePad
                              maxWidth={3}
                              minWidth={3}
                              canvasProps={{
                                className:
                                  "tc-join-call-digi-signaturepad min-height-320",
                                id: "sign-pad",
                              }}
                              ref={smallPadRef}
                              onEnd={() => handleSmallPadEnd()}
                              onBegin={() => handleSignBegin()}
                            />
                          </div>
                        )}
                        {errors && errors && (
                          <span className="tc-error">{errors && errors}</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewSignModal && (
        <SignZoomModal
          show={viewSignModal}
          error={errors}
          title="MD Signature"
          signPadRef={bigPadRef}
          clear={clearSingAndFileData}
          signatureData={signatureData}
          handleSignEnd={handleBigPadEnd}
          handleSignBegin={handleSignBegin}
          closeZoomModalHandler={closeBigSignModal}
          handleCaptureImage={handleCaptureImage}
          handleSaveSignature={handleUploadMediaFiles}
        />
      )}
      <RelieverModal
        show={showRelieverModal}
        onHide={handleShowRelieverModal}
        match={match}
        setMatch={setMatch}
        selectedReliever={selectedReliever}
        setSelectedReliever={setSelectedReliever}
        handleRelieverMD={handleRelieverMD}
      />
    </>
  );
};

export default CallSummary;
