import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateItineraryForm from "./UpdateItineraryForm";
import moment from "moment";
import { ItineraryConfirmation } from "../../components/Modals";
import { toast } from "react-toastify";
import {
  notify,
  modifyText,
  getSortData,
  getClassByFreq,
  nameFormatting,
  weekNameOptions,
  getUniqueMDData,
  summaryReportType,
  summaryWReportType,
  createMonthCalendar,
  getMonthStartEndDate,
  getStartDateAndEndDate,
  updateDashboardDataByMDList,
} from "../../helpers/commonFunction";
import Summary from "./Summary";
import SummaryItineraryList from "./SummaryItineraryList";
import { routes } from "../../helpers/constants";
import {
  db,
  getAllEntriesFromDB,
  getEntryByFieldFromDB,
  updateEntryInDB,
} from "../../helpers/db";
import { useLiveQuery } from "dexie-react-hooks";

const UpdateItinerary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const nextMonth = {
    value: moment().add(1, "months").format("M"),
    label: moment().add(1, "months").format("MMMM"),
  };

  const getItineraryFromDB = useLiveQuery(() => db.itinerary.toArray(), []);
  const getItineraryMDsFromDB = useLiveQuery(
    () => db.itineraryMDs.toArray(),
    []
  );
  const getMDListFromDB = useLiveQuery(() => db.mdList.toArray(), []);
  const getItineraryRequestFromDB = useLiveQuery(
    () => db.itineraryRequest.toArray(),
    []
  );
  const getFrequencyFromDB = useLiveQuery(() => db.frequency.toArray(), []);

  const [modalShow, setModalShow] = useState(false);
  const [selectedMD, setSelectedMD] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [itineraryMethod, setItineraryMethod] = useState(
    location?.state?.method ? location?.state?.method : ""
  );
  const [newItineraryList, setNewItineraryList] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [holidayList, setHolidayList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [mdListOptions, setMDListOptions] = useState([]);
  const [universeMDOptions, setUniverseMDOptions] = useState([]);
  const [selectedSummary, setSelectedSummary] = useState("");
  const [summaryReports, setSummaryReports] = useState(summaryReportType);
  const [summaryWReports, setSummaryWReports] = useState(summaryWReportType);
  const [currMItinerary, setCurrMItinerary] = useState([]);
  const [mdClassCurrMTotals, setMDClassCurrMTotals] = useState([]);
  const [mdClassNextMTotals, setMDClassNextMTotals] = useState([]);
  const [perWPerDTotals, setPerWPerDTotals] = useState([]);
  const [calenderData, setCalenderData] = useState([]);

  const [month, setMonth] = useState({ value: "", label: "" });
  const [monthInfo, setMonthInfo] = useState("");
  const [weekName, setWeekName] = useState("");
  const [monthYearValue, setMonthYearValue] = useState("");
  const [weekDays, setWeekDays] = useState([]);
  const [weekDaysErr, setWeekDaysErr] = useState("");
  const [itineraryId, setItineraryId] = useState("");
  const [isBlueColor, setIsBlueColor] = useState(false);
  const [previousItineraryData, setPreviousItineraryData] = useState("");
  const [isToggle, setIsToggle] = useState(true);
  const [reason, setReason] = useState("");
  const [attachment, setAttachment] = useState("");

  useEffect(() => {
    if (location?.state?.method) {
      handleInitialData(location?.state);
    } else {
      navigate(routes.itinerary);
    }
  }, [location?.state?.method]);
  useEffect(() => {
    if (itineraryMethod?.type === "SUMMARY") {
      getItinerarySummaryData(
        itineraryMethod,
        isToggle ? "md" : "call",
        getMDListFromDB || [],
        getItineraryFromDB || [],
        getItineraryMDsFromDB || [],
        getItineraryRequestFromDB || []
      );
    } else if (itineraryMethod?.type === "MDCLASS") {
      getMDClassTotalsData(
        isToggle ? "approved" : "approval",
        getMDListFromDB || [],
        getItineraryFromDB || [],
        getItineraryMDsFromDB || [],
        getItineraryRequestFromDB || []
      );
    } else if (itineraryMethod?.type === "PERWPERD") {
      getPerWeekPerDayCallTotals(
        getItineraryMDsFromDB,
        isToggle ? "call" : "md"
      );
    }
  }, [
    getMDListFromDB,
    getItineraryFromDB,
    getItineraryMDsFromDB,
    getItineraryRequestFromDB,
  ]);

  const handleInitialData = async (itinerary) => {
    setFrequency("");
    setSelectedMD("");
    setPreviousItineraryData("");
    setItineraryMethod(itinerary?.method);
    setMonthYearValue(itinerary?.itineraryMonth);
    setHolidayList(itinerary?.holidays);
    setFrequencyList(itinerary?.frequency);
    setMonth(itinerary?.nextMonth);
    setMonthInfo(itinerary?.monthInfo);
    let newDates = getMonthStartEndDate(1);
    setSelectedDate(newDates.start);
    setMinDate(newDates?.start);
    setMaxDate(newDates?.end);
    if (
      itinerary?.method?.type === "ADD" ||
      itinerary?.method?.type === "EDIT" ||
      itinerary?.method?.type === "REMOVE" ||
      itinerary?.method?.type === "VIEW"
    ) {
      const freqOptions =
        (itinerary &&
          itinerary?.frequency?.length > 0 &&
          itinerary?.frequency.map((item) => {
            const freqN = getClassByFreq(item?.name);
            return {
              ...item,
              id: item?.freq_id,
              value: item?.target,
              label: freqN.concat(" - ", item?.name),
            };
          })) ||
        [];
      setFrequencyOptions(freqOptions);

      const itineraryList = getSortData(itinerary?.itinerary, "lastname");
      const oldMDOptions =
        (itineraryList &&
          itineraryList?.length > 0 &&
          itineraryList.map((item) => {
            return {
              ...item,
              id: item.md_id,
              value: nameFormatting(item),
              label: nameFormatting(item),
            };
          })) ||
        [];
      setMDListOptions(oldMDOptions);

      const universalMDList1 = getSortData(
        itinerary?.universalMDList,
        "lastname"
      );
      const newMDOptions =
        (universalMDList1 &&
          universalMDList1?.length > 0 &&
          universalMDList1?.map((item) => {
            return {
              ...item,
              id: item.md_id,
              value: nameFormatting(item),
              label: nameFormatting(item),
            };
          })) ||
        [];
      if (itinerary?.method?.value === "ADD") {
        const sMD = newMDOptions[0];
        const sFreq =
          freqOptions &&
          freqOptions?.find((item) => item?.freq_id === sMD?.frequency_id);
        setSelectedMD(sMD);
        setFrequency(sFreq ? sFreq : freqOptions[0]);
      }
      setUniverseMDOptions(newMDOptions);
    } else if (itinerary?.method?.type === "MDCLASS") {
      getMDClassTotalsData(
        "approved",
        itinerary?.mdList,
        itinerary?.itinerary,
        itinerary?.itineraryMDs,
        itinerary?.itineraryRequest
      );
    } else if (itinerary?.method?.type === "PERWPERD") {
      getPerWeekPerDayCallTotals(itinerary?.itineraryMDs, "call");
      const calendar = createMonthCalendar(
        itinerary?.monthInfo?.nextMYear,
        itinerary?.monthInfo?.nextMonth
      );
      setCalenderData(calendar);
    } else if (
      itinerary?.method?.type === "SUMMARY" ||
      itinerary?.method?.type === "WSUMMARY"
    ) {
      getItinerarySummaryData(
        itinerary?.method,
        "md",
        itinerary?.mdList,
        itinerary?.itinerary,
        itinerary?.itineraryMDs,
        itinerary?.itineraryRequest
      );
    }
  };

  const toggleChangeHandler = () => {
    setIsToggle(!isToggle);
    if (itineraryMethod?.type === "MDCLASS") {
      !isToggle
        ? getMDClassTotalsData(
            "approved",
            getMDListFromDB,
            getItineraryFromDB,
            getItineraryMDsFromDB,
            getItineraryRequestFromDB
          )
        : getMDClassTotalsData(
            "approval",
            getMDListFromDB,
            getItineraryFromDB,
            getItineraryMDsFromDB,
            getItineraryRequestFromDB
          );
    } else if (itineraryMethod?.type === "PERWPERD") {
      !isToggle
        ? getPerWeekPerDayCallTotals(getItineraryMDsFromDB, "call")
        : getPerWeekPerDayCallTotals(getItineraryMDsFromDB, "md");
    } else if (itineraryMethod?.type === "SUMMARY") {
      !isToggle
        ? getItinerarySummaryData(
            itineraryMethod,
            "md",
            getMDListFromDB,
            getItineraryFromDB,
            getItineraryMDsFromDB,
            getItineraryRequestFromDB
          )
        : getItinerarySummaryData(
            itineraryMethod,
            "call",
            getMDListFromDB,
            getItineraryFromDB,
            getItineraryMDsFromDB,
            getItineraryRequestFromDB
          );
    }
  };

  // Filter MDs and CALLs for summary and WSummary
  const getItinerarySummaryData = (
    method,
    type,
    mdList,
    itinerary,
    itineraryMDs,
    itineraryRequest
  ) => {
    if (method.type === "SUMMARY") {
      const newSummaryReport = [...summaryReportType];
      if (type === "call") {
        if (itineraryRequest && itineraryRequest?.length > 0) {
          itineraryRequest.forEach((changeItem) => {
            newSummaryReport &&
              newSummaryReport.map((item, i) => {
                if (
                  changeItem.approve_request === item.approve_request &&
                  changeItem.type_request === item.type_request
                ) {
                  if (
                    changeItem.type_request === "EDIT" &&
                    item.approve_request === "APPROVED" &&
                    changeItem.approve_request === "APPROVED"
                  ) {
                    const oldDates = convertDataAsArray(changeItem)?.length;
                    const newDates = convertDataAsArray(
                      changeItem,
                      "new"
                    )?.length;
                    newSummaryReport[i] = {
                      ...item,
                      is_call: 1,
                      count: item.count + newDates - oldDates,
                    };
                  } else if (
                    changeItem.type_request === "EDIT" &&
                    item.approve_request === "EDIT" &&
                    changeItem.approve_request === "EDIT"
                  ) {
                    const oldDates = convertDataAsArray(changeItem)?.length;
                    const newDates = convertDataAsArray(
                      changeItem,
                      "new"
                    )?.length;
                    newSummaryReport[i] = {
                      ...item,
                      is_call: 1,
                      count: item.count + newDates - oldDates,
                    };
                  } else {
                    const oldDates = convertDataAsArray(changeItem)?.length;
                    newSummaryReport[i] = {
                      ...item,
                      is_call: 1,
                      count: item.count + oldDates,
                    };
                  }
                  return item;
                } else {
                  newSummaryReport[i] = item;
                  return item;
                }
              });
          });
        }
        setSummaryReports(newSummaryReport);
        setCurrMItinerary(mdList);
      } else {
        if (itineraryRequest && itineraryRequest?.length > 0) {
          itineraryRequest.forEach((changeItem) => {
            newSummaryReport &&
              newSummaryReport.map((item, i) => {
                if (
                  changeItem.approve_request === item.approve_request &&
                  changeItem.type_request === item.type_request
                ) {
                  newSummaryReport[i] = {
                    ...item,
                    count: item.count + 1,
                  };
                  return {
                    ...item,
                    count: item.count + 1,
                  };
                } else {
                  newSummaryReport[i] = item;
                  return item;
                }
              });
          });
        }
        setSummaryReports(newSummaryReport);
        const currMonthIti = getUniqueMDData(mdList);
        setCurrMItinerary(currMonthIti);
      }
    } else {
      let getWDates = getStartDateAndEndDate("week", "", nextMonth.value - 1);
      let weeks = getWDates?.weekDates;
      if (type === "call") {
        let newSummaryWReport = [];
        const result = convertItineraryInWeeks(weeks, itineraryMDs, type);
        result &&
          result?.length > 0 &&
          result.forEach((item, i) => {
            newSummaryWReport.push({
              ...item,
              type: "WSUMMARY",
              name: `Week ${i + 1}`,
              count: item.qty,
            });
          });
        setSummaryWReports(newSummaryWReport);
      } else {
        let newSummaryWReport = [];
        const result = convertItineraryInWeeks(weeks, itineraryMDs, type);
        result &&
          result?.length > 0 &&
          result.forEach((item, i) => {
            newSummaryWReport.push({
              ...item,
              type: "WSUMMARY",
              name: `Week ${i + 1}`,
              count: item.qty,
            });
          });
        setSummaryWReports(newSummaryWReport);
      }
    }
  };
  const convertItineraryInWeeks = (weekDates, data, type) => {
    if (type === "call") {
      return weekDates.map((week, index) => {
        const start_date_of_week = week[0];
        const end_date_of_week = week[week.length - 1];
        const week_no = index + 1;
        const filteredData = data.filter((item) => week.includes(item.date));
        const qty = filteredData.length;

        return {
          qty,
          week_no,
          start_date_of_week,
          end_date_of_week,
          itinerarys: filteredData,
        };
      });
    } else {
      return weekDates.map((week, index) => {
        const start_date_of_week = week[0];
        const end_date_of_week = week[week.length - 1];
        const week_no = index;
        const filteredData = data.filter((item) => week.includes(item.date));
        const uniqueMdIds = Array.from(
          new Set(filteredData.map((item) => item.md_id))
        ).map((md_id) => filteredData.find((item) => item.md_id === md_id));

        return {
          qty: uniqueMdIds.length,
          week_no,
          start_date_of_week,
          end_date_of_week,
          itinerarys: uniqueMdIds,
        };
      });
    }
  };
  const getSummaryReportData = async (item) => {
    if (item?.type === "SUMMARY") {
      if (item?.value === "Added") {
        const filterEntry = getItineraryRequestFromDB
          .filter(
            (item) =>
              item.approve_request === "APPROVED" &&
              item.type_request === "INSERT"
          )
          .map((newItem) => {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === newItem?.frequency_id
            );
            const className = getClassNameByFreq(newItem?.frequency_id);
            return {
              ...newItem,
              dates:
                typeof newItem?.dates === "string"
                  ? JSON.parse(newItem?.dates)
                  : newItem?.dates,
              class_name: className,
              frequency_name: getFName?.name,
            };
          });
        setNewItineraryList(filterEntry);
      } else if (item?.value === "Edited") {
        const filterEntry = getItineraryRequestFromDB
          .filter(
            (item) =>
              item.approve_request === "APPROVED" &&
              item.type_request === "EDIT"
          )
          .map((newItem) => {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === newItem?.update_frequency_id
            );
            const className = getClassNameByFreq(newItem?.update_frequency_id);
            return {
              ...newItem,
              dates:
                typeof newItem?.update_dates === "string"
                  ? JSON.parse(newItem?.update_dates)
                  : newItem?.update_dates,
              class_name: className,
              frequency_name: getFName?.name,
            };
          });
        setNewItineraryList(filterEntry);
      } else if (item?.value === "Removed") {
        const filterEntry = getItineraryRequestFromDB
          .filter(
            (item) =>
              item.approve_request === "APPROVED" &&
              item.type_request === "DELETE"
          )
          .map((newItem) => {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === newItem?.frequency_id
            );
            const className = getClassNameByFreq(newItem?.frequency_id);
            return {
              ...newItem,
              dates:
                typeof newItem?.dates === "string"
                  ? JSON.parse(newItem?.dates)
                  : newItem?.dates,
              class_name: className,
              frequency_name: getFName?.name,
            };
          });
        setNewItineraryList(filterEntry);
      } else if (item?.value === "approvalAdded") {
        const filterEntry = getItineraryRequestFromDB
          .filter(
            (item) =>
              item.approve_request === "INSERT" &&
              item.type_request === "INSERT"
          )
          .map((newItem) => {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === newItem?.frequency_id
            );
            const className = getClassNameByFreq(newItem?.frequency_id);
            return {
              ...newItem,
              dates:
                typeof newItem?.dates === "string"
                  ? JSON.parse(newItem?.dates)
                  : newItem?.dates,
              class_name: className,
              frequency_name: getFName?.name,
            };
          });
        setNewItineraryList(filterEntry);
      } else if (item?.value === "approvalEdited") {
        const filterEntry = getItineraryRequestFromDB
          .filter(
            (item) =>
              item.approve_request === "EDIT" && item.type_request === "EDIT"
          )
          .map((newItem) => {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === newItem?.update_frequency_id
            );
            const className = getClassNameByFreq(newItem?.update_frequency_id);
            return {
              ...newItem,
              dates:
                typeof newItem?.update_dates === "string"
                  ? JSON.parse(newItem?.update_dates)
                  : newItem?.update_dates,
              class_name: className,
              frequency_name: getFName?.name,
            };
          });
        setNewItineraryList(filterEntry);
      } else if (item?.value === "approvalRemoved") {
        const filterEntry = getItineraryRequestFromDB
          .filter(
            (item) =>
              item.approve_request === "DELETE" &&
              item.type_request === "DELETE"
          )
          .map((newItem) => {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === newItem?.frequency_id
            );
            const className = getClassNameByFreq(newItem?.frequency_id);
            return {
              ...newItem,
              dates:
                typeof newItem?.dates === "string"
                  ? JSON.parse(newItem?.dates)
                  : newItem?.dates,
              class_name: className,
              frequency_name: getFName?.name,
            };
          });
        setNewItineraryList(filterEntry);
      } else if (item?.value === "Unchanged") {
        const newItinerary = getItineraryFromDB.filter((item2) => {
          return !getItineraryRequestFromDB.some(
            (item1) => item1?.md_id === item2?.md_id
          );
        });
        setNewItineraryList(newItinerary);
      } else {
        setNewItineraryList(getItineraryFromDB);
      }
      setSelectedSummary(item);
    } else if (item?.type === "WSUMMARY" && item?.date) {
      setSelectedDate(item?.date);
      const newList = getItineraryMDsFromDB.filter(
        (call) => call.date === item?.date
      );
      setNewItineraryList(newList);
      setSelectedSummary(item);
    } else {
      const mdIdMap = new Map();
      item &&
        item?.itinerarys.forEach((entry) => {
          const {
            id,
            date,
            md_id,
            is_summary,
            is_visited,
            is_consecutive,
            ...rest
          } = entry;
          const formattedDate = {
            date,
            is_summary,
            is_visited,
            is_consecutive,
            mdData: entry,
          };
          if (mdIdMap.has(md_id)) {
            const existingEntry = mdIdMap.get(md_id);
            if (is_summary === 1) {
              existingEntry.actual_visits++;
            }
            existingEntry.dates.push(formattedDate);
          } else {
            mdIdMap.set(md_id, {
              ...rest,
              id,
              md_id,
              dates: [formattedDate],
              actual_visits: is_summary === 1 ? 1 : 0,
            });
          }
        });
      const uniqueEntries = Array.from(mdIdMap.values());
      setNewItineraryList(uniqueEntries);
      setSelectedSummary(item);
    }
  };

  // Filter MDs and CALLs for MD Class Totals
  const getClassNameByFreq = (frequency) => {
    return frequency === 1
      ? "A"
      : frequency === 2
      ? "B"
      : frequency === 3
      ? "C"
      : frequency === 4
      ? "D"
      : "OUTLET";
  };
  const getMDClassTotalsData = (
    type,
    mdList,
    itinerary,
    itineraryMDs,
    itineraryRequest
  ) => {
    if (type === "approved") {
      const { monthlyData: currMItinerary } =
        updateDashboardDataByMDList(mdList);
      const newItineraryList = [...currMItinerary];
      for (let i = 0; i < currMItinerary?.length; i++) {
        const currClass = currMItinerary[i];
        itineraryRequest &&
          itineraryRequest.forEach((request) => {
            const currCName = getClassNameByFreq(request?.frequency_id);
            if (
              (currClass?.class === currCName &&
                request?.approve_request === "INSERT" &&
                request?.type_request === "INSERT") ||
              (currClass?.class === currCName &&
                request?.approve_request === "APPROVED" &&
                request?.type_request === "INSERT")
            ) {
              const dates = convertDataAsArray(request)?.length;
              newItineraryList[i] = {
                ...newItineraryList[i],
                target_md: newItineraryList[i]?.target_md + 1,
                target_call: newItineraryList[i]?.target_call + dates,
              };
              return request;
            } else if (
              (currClass?.class === currCName &&
                request?.approve_request === "EDIT" &&
                request?.type_request === "EDIT") ||
              (currClass?.class === currCName &&
                request?.approve_request === "APPROVED" &&
                request?.type_request === "EDIT")
            ) {
              const oldDates = convertDataAsArray(request)?.length;
              const newDates = convertDataAsArray(request, "new")?.length;
              newItineraryList[i] = {
                ...newItineraryList[i],
                target_md: newItineraryList[i]?.target_md - 1,
                target_call: newItineraryList[i]?.target_call - oldDates,
              };
              const newCName = getClassNameByFreq(request?.update_frequency_id);
              const findNClass =
                currMItinerary &&
                currMItinerary.findIndex((item) => item?.class === newCName);
              if (findNClass === -1) return request;
              newItineraryList[findNClass] = {
                ...newItineraryList[findNClass],
                target_md: newItineraryList[findNClass]?.target_md + 1,
                target_call:
                  newItineraryList[findNClass]?.target_call + newDates,
              };
              return request;
            } else if (
              (currClass?.class === currCName &&
                request?.approve_request === "DELETE" &&
                request?.type_request === "DELETE") ||
              (currClass?.class === currCName &&
                request?.approve_request === "APPROVED" &&
                request?.type_request === "DELETE")
            ) {
              const dates = convertDataAsArray(request)?.length;
              newItineraryList[i] = {
                ...newItineraryList[i],
                target_md: newItineraryList[i]?.target_md - 1,
                target_call: newItineraryList[i]?.target_call - dates,
              };
              return request;
            } else {
              return request;
            }
          });
      }
      setMDClassCurrMTotals(currMItinerary);
      setMDClassNextMTotals(newItineraryList);
    } else {
      const { monthlyData: currMItinerary } =
        updateDashboardDataByMDList(mdList);
      const newItineraryList = [...currMItinerary];
      for (let i = 0; i < currMItinerary?.length; i++) {
        const currClass = currMItinerary[i];
        itineraryRequest &&
          itineraryRequest.forEach((request) => {
            const currCName = getClassNameByFreq(request?.frequency_id);
            if (
              currClass?.class === currCName &&
              request?.approve_request === "APPROVED" &&
              request?.type_request === "INSERT"
            ) {
              const dates = convertDataAsArray(request)?.length;
              newItineraryList[i] = {
                ...newItineraryList[i],
                target_md: newItineraryList[i]?.target_md + 1,
                target_call: newItineraryList[i]?.target_call + dates,
              };
              return request;
            } else if (
              currClass?.class === currCName &&
              request?.approve_request === "APPROVED" &&
              request?.type_request === "EDIT"
            ) {
              const oldDates = convertDataAsArray(request)?.length;
              const newDates = convertDataAsArray(request, "new")?.length;
              newItineraryList[i] = {
                ...newItineraryList[i],
                target_md: newItineraryList[i]?.target_md - 1,
                target_call: newItineraryList[i]?.target_call - oldDates,
              };
              const newCName = getClassNameByFreq(request?.update_frequency_id);
              const findNClass =
                currMItinerary &&
                currMItinerary.findIndex((item) => item?.class === newCName);
              if (findNClass === -1) return request;
              newItineraryList[findNClass] = {
                ...newItineraryList[findNClass],
                target_md: newItineraryList[findNClass]?.target_md + 1,
                target_call:
                  newItineraryList[findNClass]?.target_call + newDates,
              };
              return request;
            } else if (
              currClass?.class === currCName &&
              request?.approve_request === "APPROVED" &&
              request?.type_request === "DELETE"
            ) {
              const dates = convertDataAsArray(request)?.length;
              newItineraryList[i] = {
                ...newItineraryList[i],
                target_md: newItineraryList[i]?.target_md - 1,
                target_call: newItineraryList[i]?.target_call - dates,
              };
              return request;
            } else {
              return request;
            }
          });
      }
      setMDClassCurrMTotals(currMItinerary);
      setMDClassNextMTotals(newItineraryList);
    }
  };
  const getMDsByClass = async (item, type) => {
    if (type === "approved") {
      const getUniqueMD =
        getMDListFromDB &&
        getMDListFromDB?.length > 0 &&
        getUniqueMDData(getMDListFromDB);
      const filterMDsByClass =
        getUniqueMD &&
        getUniqueMD?.length > 0 &&
        getUniqueMD.filter((call) => call?.name === item?.class);
      setNewItineraryList(filterMDsByClass);
      setSelectedSummary({
        ...item,
        name: "MD CLASS TOTALS",
      });
    } else {
      if (isToggle) {
        const filterMDsByClass =
          getItineraryFromDB &&
          getItineraryFromDB?.length > 0 &&
          getItineraryFromDB.filter((call) => call?.class_name === item?.class);
        setNewItineraryList(filterMDsByClass);
        setSelectedSummary({
          ...item,
          name: "MD CLASS TOTALS",
        });
      } else {
        const filterMDsByClass =
          getItineraryFromDB &&
          getItineraryFromDB?.length > 0 &&
          getItineraryFromDB.filter((call) => call?.class_name === item?.class);
        let newMDsByClass = [...filterMDsByClass];
        for (let i = 0; i < getItineraryRequestFromDB.length; i++) {
          const request = getItineraryRequestFromDB[i];
          const className = getClassNameByFreq(request?.frequency_id);
          if (
            request?.approve_request === "APPROVED" &&
            request?.type_request === "INSERT" &&
            className === filterMDsByClass[0]?.class_name
          ) {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === request?.frequency_id
            );
            const newDates = request?.dates
              ? typeof request?.dates === "string"
                ? JSON.parse(request?.dates)
                : request?.dates
              : [];
            newMDsByClass.push({
              ...request,
              dates: newDates,
              class_name: className,
              frequency_name: getFName?.name,
            });
          } else if (
            request?.approve_request === "APPROVED" &&
            request?.type_request === "EDIT" &&
            className === filterMDsByClass[0]?.class_name
          ) {
            const getFName = getFrequencyFromDB.find(
              (item) => item.freq_id === request?.update_frequency_id
            );
            const newDates = request?.update_dates
              ? typeof request?.update_dates === "string"
                ? JSON.parse(request?.update_dates)
                : request?.update_dates
              : [];
            const index = newMDsByClass.findIndex(
              (item) => item?.md_id === request?.md_id
            );
            if (index) {
              newMDsByClass[index] = {
                ...newMDsByClass[index],
                dates: newDates,
                frequency_name: getFName?.name,
              };
            }
          }
        }
        setNewItineraryList(newMDsByClass);
        setSelectedSummary({
          ...item,
          name: "MD CLASS TOTALS",
        });
      }
    }
  };

  // Filter CALLs by per week and Per day
  const getPerWeekPerDayCallTotals = async (itineraryMDs, type) => {
    const getWeekNumber = (date) => {
      const firstDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      ).getDay();
      return Math.ceil((date.getDate() + firstDay) / 7);
    };
    const weeks = Array.from({ length: 5 }, (_, i) => ({
      week: i + 1,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      total: 0,
      wTotal: 0,
      uniqueMDs: {
        mon: new Set(),
        tue: new Set(),
        wed: new Set(),
        thu: new Set(),
        fri: new Set(),
      },
      dayWiseTotal: {
        mon: new Set(),
        tue: new Set(),
        wed: new Set(),
        thu: new Set(),
        fri: new Set(),
      },
    }));
    if (type === "call") {
      itineraryMDs &&
        itineraryMDs?.length > 0 &&
        itineraryMDs.forEach((call) => {
          const date = new Date(call.date);
          const weekNumber = getWeekNumber(date);
          const day = date
            .toLocaleString("en-US", { weekday: "short" })
            .toLowerCase();
          if (
            weekNumber <= 5 &&
            ["mon", "tue", "wed", "thu", "fri"].includes(day)
          ) {
            weeks[weekNumber - 1][day]++;
            weeks[weekNumber - 1].total++;
          }
        });
    } else {
      itineraryMDs &&
        itineraryMDs.length > 0 &&
        itineraryMDs.forEach((call) => {
          const date = new Date(call.date);
          const weekNumber = getWeekNumber(date);
          const day = date
            .toLocaleString("en-US", { weekday: "short" })
            .toLowerCase();

          if (
            weekNumber <= 5 &&
            ["mon", "tue", "wed", "thu", "fri"].includes(day)
          ) {
            weeks[weekNumber - 1][day]++;
            weeks[weekNumber - 1].uniqueMDs[day].add(call.md_id);
            weeks[weekNumber - 1].dayWiseTotal[day].add(call.md_id);
          }
        });

      weeks.forEach((week) => {
        const combinedUniqueMDs = new Set([
          ...week.uniqueMDs.mon,
          ...week.uniqueMDs.tue,
          ...week.uniqueMDs.wed,
          ...week.uniqueMDs.thu,
          ...week.uniqueMDs.fri,
        ]);
        week.wTotal = combinedUniqueMDs.size;
        delete week.uniqueMDs;
      });
    }
    setPerWPerDTotals(weeks);
  };

  const setItineraryDetailsValues = (response, md) => {
    setPreviousItineraryData(response);
    setSelectedMD({
      ...md,
      clinic_name: response.clinic_name,
      code: response.code,
    });
    setItineraryId(response?.itinerary_id);
    const sFrequency = frequencyList.find(
      (item) => item.freq_id === response.frequency_id
    );
    const freqN = getClassByFreq(sFrequency?.name);
    const updatedFrequency = {
      ...sFrequency,
      id: sFrequency?.freq_id,
      value: sFrequency.target,
      label: freqN.concat(" - ", sFrequency?.name),
    };
    setFrequency(updatedFrequency);

    // For set WeekName
    const actualDates =
      typeof response?.dates === "string"
        ? JSON.parse(response?.dates)
        : response?.dates;
    setWeekName({
      value: moment(actualDates[0].date).format("ddd"),
      label: moment(actualDates[0].date).format("ddd"),
    });

    // For weekDays
    if (
      itineraryMethod.value === "VIEW" ||
      itineraryMethod.value === "REMOVE"
    ) {
      const weekDaysValue = actualDates.map((dateObj) => ({
        date: moment(dateObj.date, "YYYY-MM-DD").format("MM/DD/YY"),
        isGreen: true,
      }));

      holidayList.length !== 0 &&
        holidayList.forEach((holiday) => {
          const index = weekDaysValue.findIndex(
            (dateObj) =>
              dateObj.date ===
              moment(holiday.date, "YYYY-MM-DD").format("MM/DD/YY")
          );
          if (index !== -1) {
            weekDaysValue[index].isHoliday = true;
          }
        });
      setWeekDays(weekDaysValue);
    } else {
      getWeekDaysValuesForEdit(
        monthYearValue,
        moment(actualDates[0].date).format("ddd"),
        actualDates
      );
    }
  };
  const getWeekDaysValuesForEdit = (monthYear, weekDay, actualDaysList) => {
    const firstDayOfMonth = moment(monthYear, "MMMM YYYY").startOf("month");
    const lastDayOfMonth = moment(monthYear, "MMMM YYYY").endOf("month");

    const totalWeekDays = [];
    let currentDay = firstDayOfMonth.clone().day(weekDay);

    if (currentDay.isBefore(firstDayOfMonth)) {
      currentDay.add(1, "week");
    }
    while (currentDay.isSameOrBefore(lastDayOfMonth)) {
      const dateObj = {
        date: currentDay.format("MM/DD/YY"),
        isGreen: false,
      };
      totalWeekDays.push(dateObj);
      currentDay.add(7, "days");
    }

    actualDaysList &&
      actualDaysList?.length > 0 &&
      actualDaysList.forEach((actual) => {
        const index = totalWeekDays.findIndex(
          (dateObj) =>
            dateObj.date ===
            moment(actual.date, "YYYY-MM-DD").format("MM/DD/YY")
        );
        if (index !== -1) {
          totalWeekDays[index].isGreen = true;
        }
      });
    setWeekDays(totalWeekDays);
  };
  const getItineraryDetails = async (md) => {
    const getMatchMD = await getEntryByFieldFromDB(
      "itinerary",
      "md_id",
      md.md_id
    );
    if (getMatchMD && getMatchMD?.length > 0) {
      setItineraryDetailsValues(getMatchMD[0], md);
    } else {
      toast.warning(
        `No Itinerary Found for ${nameFormatting(md)} for ${month.label}!`,
        {
          autoClose: 3500,
        }
      );
      setFrequency("");
      setWeekDays([]);
      setWeekName("");
      setItineraryId("");
      setPreviousItineraryData("");
    }
  };
  const getWeekDaysFromMonths = (monthYear, weekDay, freqData) => {
    const newFrequency = freqData ? freqData : frequency;
    const firstDayOfMonth = moment(monthYear, "MMMM YYYY").startOf("month");
    const lastDayOfMonth = moment(monthYear, "MMMM YYYY").endOf("month");

    const daysArray = [];
    let currentDay = firstDayOfMonth.clone().day(weekDay);

    if (currentDay.isBefore(firstDayOfMonth)) {
      currentDay.add(1, "week");
    }

    while (currentDay.isSameOrBefore(lastDayOfMonth)) {
      const dateObj = {
        date: currentDay.format("MM/DD/YY"),
      };
      daysArray.push(dateObj);
      currentDay.add(7, "days");
    }

    if (+newFrequency.value === 4 && daysArray.length >= 4) {
      if (daysArray.length === 5) {
        daysArray[0].isGreen = true;
        daysArray[1].isGreen = true;
        daysArray[2].isGreen = true;
        daysArray[3].isGreen = true;
        daysArray[4].isGreen = false;
      } else if (daysArray.length === 4) {
        daysArray[0].isGreen = true;
        daysArray[1].isGreen = true;
        daysArray[2].isGreen = true;
        daysArray[3].isGreen = true;
      }
    } else if (+newFrequency.value === 3) {
      if (daysArray.length === 5) {
        daysArray[0].isGreen = true;
        daysArray[1].isGreen = false;
        daysArray[2].isGreen = true;
        daysArray[3].isGreen = false;
        daysArray[4].isGreen = true;
      } else if (daysArray.length === 4) {
        daysArray[0].isGreen = true;
        daysArray[1].isGreen = false;
        daysArray[2].isGreen = true;
        daysArray[3].isGreen = true;
      }
    } else if (
      +newFrequency.value === 2 &&
      (daysArray.length === 5 || daysArray.length === 4)
    ) {
      daysArray[0].isGreen = true;
      daysArray[1].isGreen = false;
      daysArray[2].isGreen = true;
      daysArray[3].isGreen = false;
      if (daysArray.length === 5) {
        daysArray[4].isGreen = false;
      }
    } else if (+newFrequency.value === 1) {
      daysArray[0].isGreen = true;
      daysArray[1].isGreen = false;
      daysArray[2].isGreen = false;
      daysArray[3].isGreen = false;
      if (daysArray.length === 5) {
        daysArray[4].isGreen = false;
      }
    }
    setWeekDays(daysArray);
  };
  const handleChangeValues = (event, type, name) => {
    if (type === "option") {
      if (name === "selectedMD") {
        setSelectedMD(event);
        setReason("");
        setAttachment("");
        if (itineraryMethod.value !== "ADD") {
          getItineraryDetails(event);
          setIsBlueColor(false);
        } else {
          const sFreq =
            frequencyOptions &&
            frequencyOptions?.find(
              (item) => item?.freq_id === event?.frequency_id
            );
          setFrequency(sFreq ? sFreq : frequencyOptions[0]);
        }
      } else if (name === "frequency") {
        setFrequency(event);
        if (itineraryMethod.value === "EDIT") {
          getWeekDaysFromMonths(monthYearValue, weekName.value, event);
        }
      } else if (name === "weekName") {
        setWeekName(event);
        getWeekDaysFromMonths(monthYearValue, event.value);
      }
      if (itineraryMethod.value !== "EDIT" && name !== "weekName") {
        setWeekName("");
        setWeekDays([]);
      }
      setWeekDaysErr("");
    } else if (type === "text") {
      setReason(event.target.value);
    } else if (type === "file") {
      if (event?.target) {
        const file = event?.target?.files[0];
        setAttachment(file);
      } else {
        setAttachment(event);
      }
    }
  };
  const handleItineraryDates = (index) => {
    if (
      (frequency.value !== 4 ||
        (frequency.value === 4 && weekDays.length === 5)) &&
      itineraryMethod.value !== "VIEW" &&
      itineraryMethod.value !== "REMOVE"
    ) {
      let greenCount = 0;

      const preWeekDays = [...weekDays];
      preWeekDays[index].isGreen = !preWeekDays[index].isGreen;

      for (let i = 0; i < preWeekDays.length; i++) {
        if (preWeekDays[i].isGreen) {
          greenCount++;
        }
      }

      if (greenCount > frequency.value) {
        for (let i = 0; i < preWeekDays.length; i++) {
          if (preWeekDays[i].isGreen && i !== index) {
            preWeekDays[i].isGreen = false;
            greenCount--;
          }

          if (greenCount === frequency.value) {
            break;
          }
        }
      }
      setWeekDaysErr("");
      setWeekDays(preWeekDays);
    }
  };
  const returnBackBtn = () => {
    navigate(-1);
    setMonth("");
    setSelectedMD("");
    setFrequency("");
    setItineraryId("");
    setWeekName("");
    setIsBlueColor(false);
    setSummaryReports(summaryReportType);
    setSummaryWReports(summaryWReportType);
    setReason("");
    setAttachment("");
  };

  // INSERT/EDIT/DELETE itinerary
  const validate = () => {
    if (!weekDays.length) {
      setWeekDaysErr("Please select day!");
      return false;
    } else if (
      weekDays.filter((day) => day.isGreen).length !== frequency.value
    ) {
      setWeekDaysErr(
        `Please select ${
          frequency.value === 1
            ? frequency.value + " day"
            : frequency.value + " days"
        }!`
      );
      return false;
    } else {
      return true;
    }
  };
  const handleConfirmationModal = () => {
    if (itineraryMethod.value === "REMOVE" && !isBlueColor) {
      setIsBlueColor(true);
    } else if (itineraryMethod.value !== "REMOVE") {
      if (validate()) {
        setModalShow(true);
      }
    } else {
      setModalShow(true);
    }
  };
  const addItineraryHandler = async () => {
    const [itinerary, itineraryRequest] = await Promise.all([
      getAllEntriesFromDB("itinerary"),
      getAllEntriesFromDB("itineraryRequest"),
    ]);
    if (itineraryMethod.value === "ADD") {
      const newDate = getMonthStartEndDate(1);
      const data = {
        md_id: selectedMD.id,
        firstname: selectedMD?.firstname,
        lastname: selectedMD?.lastname,
        frequency_id: frequency.id,
        month: month.label,
        year: monthYearValue.split(" ").pop(),
        dates: filterWeekdays(weekDays),
        start: moment(newDate.start).format("YYYY-MM-DD"),
        end: moment(newDate.end).format("YYYY-MM-DD"),
        reason: reason,
        attachment: attachment,
        type_request: "INSERT",
        approve_request: "INSERT",
      };

      const inItinerary = await filterMDByName(itinerary, data);
      const inApproval = await filterMDByStatus(itineraryRequest, data);
      if (inItinerary && inItinerary?.length > 0) {
        notify("Itinerary Already Exist!", "error");
      } else if (inApproval && inApproval?.length > 0) {
        notify("Itinerary Already Added For Approval!", "error");
      } else {
        let addedID = await updateEntryInDB(
          "itineraryRequest",
          "md_id",
          data.md_id,
          data
        );
        await updateEntryInDB("addedItinerary", "md_id", data.md_id, {
          ...data,
          id: addedID,
        });
        notify("Itinerary has been added!", "success");
        handleSetDefaultValues();
      }
    } else if (itineraryMethod.value === "EDIT") {
      const data = {
        md_id: selectedMD.id,
        firstname: selectedMD?.firstname,
        lastname: selectedMD?.lastname,
        frequency_id: previousItineraryData.frequency_id,
        month: month.label,
        year: monthYearValue.split(" ").pop(),
        dates: previousItineraryData.dates,
        itinerary_id: itineraryId,
        update_frequency_id: frequency.id,
        update_dates: filterWeekdays(weekDays),
        reason: reason,
        attachment: attachment,
        type_request: "EDIT",
        approve_request: "EDIT",
      };
      let addedID = await updateEntryInDB(
        "itineraryRequest",
        "md_id",
        data.md_id,
        data
      );
      await updateEntryInDB("addedItinerary", "md_id", data.md_id, {
        ...data,
        id: addedID,
      });
      notify("Itinerary has been Updated!", "success");
      handleSetDefaultValues();
    } else {
      const data = {
        md_id: selectedMD.id,
        firstname: selectedMD?.firstname,
        lastname: selectedMD?.lastname,
        itinerary_id: itineraryId,
        frequency_id: previousItineraryData.frequency_id,
        dates: filterWeekdays(weekDays),
        month: month.label,
        year: monthYearValue.split(" ").pop(),
        reason: reason,
        attachment: attachment,
        type_request: "DELETE",
        approve_request: "DELETE",
      };
      const inApproval = await filterMDByStatus(itineraryRequest, data);
      if (inApproval && inApproval?.length > 0) {
        notify("Itinerary Already Added For Approval!", "error");
      } else {
        let addedID = await updateEntryInDB(
          "itineraryRequest",
          "md_id",
          data.md_id,
          data
        );
        await updateEntryInDB("addedItinerary", "md_id", data.md_id, {
          ...data,
          id: addedID,
        });
        notify("Itinerary has been Removed!", "success");
        handleSetDefaultValues();
      }
    }
  };
  const handleSetDefaultValues = () => {
    setSelectedMD("");
    setMonth(location?.state?.currentMonth);
    setItineraryMethod("");
    setFrequency(frequencyOptions[0]);
    setWeekDaysErr("");
    setWeekName("");
    setModalShow(false);
    setWeekDays([]);
    setItineraryId("");
    setIsBlueColor(false);
    setPreviousItineraryData("");
    setReason("");
    setAttachment("");
    navigate(routes.itinerary);
  };

  // Filter and Modify data
  const createMDsId = (numberId) => {
    let stringWithLeadingZeros = String(numberId).padStart(5, "0");
    return stringWithLeadingZeros;
  };
  const displayDaysHours = (string) => {
    const newArr = string.split(", ");
    return newArr;
  };
  const filterWeekdays = (daysArr) => {
    const availableWeekDays = daysArr
      .filter((day) => day.isGreen)
      .map((day) => ({
        date: moment(day.date, "MM/DD/YY").format("YYYY-MM-DD"),
      }));

    return availableWeekDays;
  };
  const filterMDByName = (itinerary, data) => {
    return itinerary.filter(
      (item) =>
        modifyText(item?.firstname) === modifyText(data?.firstname) &&
        modifyText(item?.lastname) === modifyText(data?.lastname)
    );
  };
  const filterMDByStatus = (itinerary, data) => {
    return itinerary.filter(
      (item) =>
        item?.approve_request === data?.approve_request &&
        modifyText(item?.firstname) === modifyText(data?.firstname) &&
        modifyText(item?.lastname) === modifyText(data?.lastname)
    );
  };
  const convertDataAsArray = (data, type) => {
    let newData = [];
    if (data && type === "new") {
      if (typeof data?.update_dates === "string") {
        newData = JSON.parse(data?.update_dates);
      } else {
        newData = data?.update_dates;
      }
    } else if (data) {
      if (typeof data?.dates === "string") {
        newData = JSON.parse(data?.dates);
      } else {
        newData = data?.dates;
      }
    } else {
      newData = [];
    }
    return newData;
  };
  const tableHeader = (type) => {
    let headList = [];
    if (type === "MDCLASS") {
      headList = ["MD CLASS", "# OF CALLS", "# OF MDs"];
    } else if (type === "PERWPERD") {
      headList = ["Week", "Mon", "Tue", "Wed", "Thu", "Fri", "Total"];
    } else if (type === "SUMMARY") {
      if (isToggle) {
        headList = ["Updates Itinerary", "#"];
      } else {
        headList = ["Itinerary", "#"];
      }
    } else if (type === "WSUMMARY") {
      if (isToggle) {
        headList = ["WEEKS", "# OF MDs TO VISIT"];
      } else {
        headList = ["WEEKS", "# OF PLANNED CALLS"];
      }
    }
    return headList;
  };

  return (
    <>
      {(itineraryMethod.type === "SUMMARY" ||
        itineraryMethod.type === "WSUMMARY" ||
        itineraryMethod.type === "MDCLASS" ||
        itineraryMethod.type === "PERWPERD") &&
      !selectedSummary ? (
        <Summary
          minDate={minDate}
          maxDate={maxDate}
          isToggle={isToggle}
          monthInfo={monthInfo}
          tableHeader={tableHeader}
          selectedDate={selectedDate}
          calenderData={calenderData}
          returnBackBtn={returnBackBtn}
          summaryReports={
            itineraryMethod.type === "SUMMARY"
              ? summaryReports
              : itineraryMethod.type === "WSUMMARY"
              ? summaryWReports
              : itineraryMethod.type === "MDCLASS"
              ? mdClassCurrMTotals
              : perWPerDTotals
          }
          summaryReports2={mdClassNextMTotals}
          itineraryMethod={itineraryMethod}
          toggleChangeHandler={toggleChangeHandler}
          getSummaryReportData={
            itineraryMethod.type === "MDCLASS"
              ? getMDsByClass
              : getSummaryReportData
          }
          itinerarySummaryData={currMItinerary}
        />
      ) : selectedSummary ? (
        <SummaryItineraryList
          minDate={minDate}
          maxDate={maxDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedSummary={selectedSummary}
          itineraryMethod={itineraryMethod}
          summaryReportData={newItineraryList}
          returnBackBtn={() => setSelectedSummary("")}
          getItineraryMDsFromDB={getItineraryMDsFromDB}
        />
      ) : (
        <UpdateItineraryForm
          month={month}
          reason={reason}
          weekName={weekName}
          weekDays={weekDays}
          frequency={frequency}
          selectedMD={selectedMD}
          attachment={attachment}
          isBlueColor={isBlueColor}
          weekDaysErr={weekDaysErr}
          createMDsId={createMDsId}
          weekOptions={weekNameOptions}
          mdListOptions={mdListOptions}
          returnBackBtn={returnBackBtn}
          monthYearValue={monthYearValue}
          itineraryMethod={itineraryMethod}
          frequencyOptions={frequencyOptions}
          universeMDOptions={universeMDOptions}
          displayDaysHours={displayDaysHours}
          handleChangeValues={handleChangeValues}
          handleItineraryDates={handleItineraryDates}
          handleConfirmationModal={handleConfirmationModal}
        />
      )}
      {modalShow && (
        <ItineraryConfirmation
          show={modalShow}
          onHide={() => setModalShow(false)}
          itineraryMethod={itineraryMethod}
          addItineraryHandler={addItineraryHandler}
        />
      )}
    </>
  );
};

export default UpdateItinerary;
