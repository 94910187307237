import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { NewCameraIcon, zoomOut } from "../../icon/icon";
import SignaturePad from "react-signature-canvas";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { checkSystemWebcam } from "../../helpers/commonFunction";
import { isDesktop } from "react-device-detect";
import CustomWebCam from "../CustomWebCam";
import CustomImageCapture from "../CustomImageCapture";

const SignZoomModal = (props) => {
  const {
    error,
    clear,
    title,
    signPadRef,
    signatureData,
    handleSignEnd,
    handleSignBegin,
    handleCaptureImage,
    handleSaveSignature,
    closeZoomModalHandler,
    ...rest
  } = props;

  const inputRef = useRef();
  const webcamRef = useRef(null);
  const [zoom, setZoom] = useState(1.5);

  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [showWebcam, setShowWebcam] = useState(false);
  const handleShowWebcam = () => {
    setShowWebcam(!showWebcam);
  };

  useEffect(() => {
    if (signPadRef.current && signatureData) {
      signPadRef.current.clear();
      signPadRef.current.fromDataURL(signatureData);
      setTimeout(() => {
        signPadRef.current.clear();
        signPadRef.current.fromDataURL(signatureData);
      }, 100);
    }
  }, [isLandscape, isPortrait]);
  useEffect(() => {
    const handleResize = () => {
      if (signPadRef.current && signatureData) {
        signPadRef.current.clear();
        signPadRef.current.fromDataURL(signatureData);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [signatureData, signPadRef]);

  const handleSystemWebcam = () => {
    checkSystemWebcam()
      .then(() => {
        if (isDesktop) {
          handleShowWebcam();
        } else {
          inputRef.current.click();
        }
      })
      .catch(() => {});
  };

  const capture = useCallback(() => {
    const video = webcamRef.current.video;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const scaledWidth = video.videoWidth / zoom;
    const scaledHeight = video.videoHeight / zoom;
    const offsetX = (video.videoWidth - scaledWidth) / 2;
    const offsetY = (video.videoHeight - scaledHeight) / 2;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(
      video,
      offsetX,
      offsetY,
      scaledWidth,
      scaledHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const imageSrc = canvas.toDataURL("image/png");
    if (imageSrc) {
      handleCaptureImage(imageSrc);
    }
  }, [webcamRef, zoom]);

  const handleSubmit = () => {
    if (showWebcam) {
      capture();
      setShowWebcam(false);
    } else {
      setShowWebcam(false);
      handleSaveSignature("", true);
    }
  };

  const handleCloseModal = () => {
    if (title === "Quick Sign" || title === "MD Signature") {
      if (signatureData) {
        handleSubmit();
      } else {
        closeZoomModalHandler();
      }
    } else {
      closeZoomModalHandler();
    }
    setShowWebcam(false);
  };

  return (
    <Modal
      {...rest}
      className="tc-sign-modal tc-main-modal"
      fullscreen={true}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-between">
            <div>
              <h3>{title ? title : ""}</h3>
            </div>
            <div className="popups-btn">
              {title !== "DM Signature" && (
                <button
                  className="tc-camera"
                  onClick={() => handleSystemWebcam()}
                >
                  {NewCameraIcon}
                </button>
              )}
              <button
                className="tc-quicksign-signature-zoom-in"
                onClick={() => handleCloseModal()}
              >
                {zoomOut}
              </button>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tc-modal-quicksign-signature-box tc-quick-sign-signature-box">
          {isDesktop ? (
            <>
              {showWebcam && (
                <div className="mb-5">
                  <CustomWebCam
                    zoom={zoom}
                    setZoom={setZoom}
                    webcamRef={webcamRef}
                  />
                </div>
              )}
            </>
          ) : (
            <CustomImageCapture
              inputRef={inputRef}
              handleFileChange={handleCaptureImage}
            />
          )}
          {!showWebcam && (
            <SignaturePad
              maxWidth={3}
              minWidth={3}
              canvasProps={{
                className:
                  "tc-modal-quicksign-signaturepad tc-quicksign-signaturepad",
                id: "sign-pad",
              }}
              ref={signPadRef}
              onEnd={() => handleSignEnd()}
              onBegin={() => handleSignBegin()}
            />
          )}
        </div>
        <span className="tc-error">{error && error}</span>
        <div
          className="tc-md-list-detail-bottom-btn-content tc-quick-sign-btn-row"
          style={{ marginTop: 0 }}
        >
          <Button variant="danger" type="button" onClick={() => clear(true)}>
            Erase
          </Button>
          <Button variant="primary" type="button" onClick={handleSubmit}>
            Capture
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignZoomModal;
