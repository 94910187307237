import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { backBtnIcon } from "../../icon/icon";
import { useSelector } from "react-redux";
import { nameFormatting } from "../../helpers/commonFunction";
import { DoctorIcon } from "../../images";
import { CallActivitiesMDDetails } from "../../components";

const PostCall = (props) => {
  const {
    location,
    isLoading,
    selectedMD,
    showPostCall,
    savedCallData,
    handleShowPostCall,
    handleSaveCallRecordingData,
    handleSubmitCallRecordingData,
  } = props;
  const savedCall = savedCallData && savedCallData?.data?.postCall;
  const {
    uniqueProductDObj: productDObj,
    productSObj,
    jointCObj,
  } = useSelector((state) => state.callRecordingReducer);

  const [postCallObj, setPostCallObj] = useState({
    call_id: "",
    signature_url: "",
    post_call: "",
    next_obj: "",
  });

  useEffect(() => {
    if (showPostCall === true && savedCall && savedCall?.post_call) {
      setPostCallObj({ ...postCallObj, post_call: savedCall?.post_call });
    }
  }, [showPostCall]);

  const handleChange = (event, eventType) => {
    if (eventType === "options") {
      setPostCallObj({
        ...postCallObj,
        next_obj: event,
      });
    } else {
      const { name, value } = event.target;
      setPostCallObj({
        ...postCallObj,
        [name]: value,
      });
    }
  };

  const handlePostCallErrors = () => {
    handleSubmitCallRecordingData(postCallObj);
  };

  return (
    <div className={`tc-sidebar-section ${showPostCall && "zl-sidebar-open"}`}>
      <div className="tc-sidebar-navbar">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => handleShowPostCall("back")}
          disabled={isLoading}
        >
          {backBtnIcon}
        </button>
        <div className="tc-sidebar-right-btn"></div>
      </div>
      <div className="tc-sidebar-body-wrapper">
        <div className="tc-sidebar-body">
          <div className="tc-profile-wrapper">
            <img src={DoctorIcon} alt="doctor" />
            <span className="tc-profile-name">
              {selectedMD && nameFormatting(selectedMD)}
            </span>
          </div>
          <div className="h-100">
            <div className="tc-content-wrapper h-100">
              <CallActivitiesMDDetails selectedMD={selectedMD} />
              <div className="tc-content-wrapper-title d-flex justify-content-between">
                <h2 className="m-0">POST CALL</h2>
                <div className="tc-md-list-detail-bottom-btn-content d-flex gap-3">
                  {!location?.state?.is_incidental && (
                    <Button
                      variant="primary"
                      type="button"
                      className="btn-min"
                      onClick={() =>
                        handleSaveCallRecordingData("", "", postCallObj)
                      }
                      disabled={isLoading}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    value="primary"
                    type="button"
                    className="btn-min"
                    onClick={() => handlePostCallErrors()}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <div className="min-height-390 overflow-auto border-radius-12">
                <div className="tc-table-wrapper poll-call-table mb-0">
                  <Table className="tc-table tc-border-bottom-table" responsive>
                    <thead>
                      <tr>
                        <th>Activity</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="text-start ps-0"
                          style={{ minWidth: 200 }}
                        >
                          Product Detailing
                        </td>
                        <td className="text-start">
                          {productDObj && productDObj?.length > 0
                            ? productDObj.map((item, i) => {
                                return <p key={i}>{item.name}</p>;
                              })
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="text-start ps-0"
                          style={{ minWidth: 200 }}
                        >
                          Product Sampling
                        </td>
                        <td className="text-start">
                          {productSObj && productSObj?.length > 0
                            ? productSObj.map((item, i) => {
                                return (
                                  <span key={i} className="d-flex gap-4">
                                    <p>{item?.name}</p>
                                    <p>{item?.quantity}</p>
                                  </span>
                                );
                              })
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="text-start ps-0"
                          style={{ minWidth: 200 }}
                        >
                          Joint Call
                        </td>
                        <td className="text-start">
                          <p>
                            {`${
                              jointCObj && jointCObj?.dm_name
                                ? "with " + jointCObj?.dm_name
                                : "-"
                            }`}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="text-start ps-0"
                          style={{ minWidth: 200 }}
                        >
                          POST CALL ANALYSIS
                        </td>
                        <td>
                          <div className="tc-md-list-behavior-input-box">
                            <div className="tc-md-list-behavior-input-tectarea-box">
                              <textarea
                                className="form-control"
                                style={{ height: "250px" }}
                                name="post_call"
                                value={postCallObj.post_call}
                                onChange={(e) => handleChange(e)}
                                disabled={isLoading}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PostCall;
